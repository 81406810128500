import React from 'react';
import { Outlet } from 'react-router-dom';
import { StandardLayout } from 'spark-core-dx/components';
import { ReportsDomainContextProvider } from './ReportsDomainProvider';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const ReportsDomain = () => {
    return (
        <ReportsDomainContextProvider>
            <StandardLayout>
                <Outlet />
            </StandardLayout>
        </ReportsDomainContextProvider>
    );
};

export default ReportsDomain;