import React, { useState, useEffect } from 'react'
import { FieldControl, Loading, DialogControl, IconControl } from 'spark-core-dx/components';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import WizardFooter from './WizardFooter';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks';
import { useCoreContext } from 'spark-core-dx/contexts';
import { SystemTypes } from 'spark-core-dx/services';

const SystemConfiguration = ({ setCurrentTab }) => {
    const itemService = new ItemServiceFactory()
    const coreContext = useCoreContext();
    const { clientItem, handleProgress, setClientItem, setOnboardingItem, updateOnboardingProcess, setCurrentStep, onboardingItem } = useWizardDomainContext();
    const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);
    const emailReqMgr = useGenericManager(itemService.ItemServiceEnum.EmailRequest, itemService);
    const onboardingMgr = useGenericManager(itemService.ItemServiceEnum.Onboarding, itemService);
    const emailTemplateMgr = useGenericManager(itemService.ItemServiceEnum.EmailTemplate, itemService);
    const configFieldNames = ["AzureSubscriptionId", "AZTenant", "AZGraphClientId", "AZGraphClientSecret"]
    const { hasAndShowValidationErrors } = useValidationErrors();
    const [config, setConfig] = useState({
        ClientId: clientItem?._id,
        AzureSubscriptionId: "",
        AZTenant: "",
        AZGraphClientId: "",
        AZGraphClientSecret: ""
    })
    const [selectedOption, setSelectedOption] = useState("single");
    const [confirmingFranchiseeSetup, setConfirmingFranchiseeSetup] = useState(onboardingItem.SystemConfigurationCompleted === false)
    const [openSuccessDialog, setOpenSuccessDialog] = useState(onboardingItem.SystemConfigurationCompleted === true && onboardingItem.Step === 1)
    const [error, setError] = useState(false)
    const [openInviteDialog, setOpenInviteDialog] = useState(null);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
    const [configLoading, setConfigLoading] = useState(true)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if ((configMgr.uiMetadata && selectedOption === "single" && !configLoading && config)) {
            setIsLoading(false)
        }
    }, [configMgr, selectedOption])

    useEffect(() => {
        configMgr.search({ searchModel: "x => x.IsActive == true && x.IsDeleted == false && x.ClientId == cid", params: { cid: clientItem?._id } }).then(r => {
            if (r?.length > 0) {
                const resultedConfig = r.first();
                setConfigLoading(false)
                setConfig(resultedConfig)
            } else {
                setConfigLoading(false)
            }
        })


    }, [])

    const handleProgressUpdate = () => {
        const filledFields = Object.entries(config)
            .filter(([key, value]) => configFieldNames.includes(key) && value?.trim() !== "")
            .length;

        const totalFields = Object.keys(config)
            .filter(key => configFieldNames.includes(key))
            .length;

        return { filledFields, totalFields }

        // handleProgress(filledFields, totalFields, "page14")
    }

    const handleOnChange = (value, fieldName) => {
        setConfig(prev => {
            return { ...prev, [fieldName]: value }
        })
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleOnSave = async (isContinue) => {
        if (!hasAndShowValidationErrors("config")) {
            if (isContinue) {
                setConfirmingFranchiseeSetup(true)
                await onboardingMgr.RunOperation({
                    operationName: "ValidateFranchiseeSetup",
                    queryParams: {
                        azsubid: config.AzureSubscriptionId,
                        aztenantid: config.AZTenant,
                        azappid: config.AZGraphClientId,
                        azappsecret: config.AZGraphClientSecret,
                        clientid: config.ClientId,
                        oid: onboardingItem.UniqueId
                    }
                }).then(r => {
                    if (r.Success) {
                        setClientItem(r.Items.first().Client)
                        setOnboardingItem(r.Items.first().Onboarding)
                        setConfig(r.Items.first().Config)
                    } else {
                        setError(true)
                        setConfirmingFranchiseeSetup(false)
                    }
                })

            } else {
                await configMgr.saveItem({ ...config, Environment: coreContext?.Launch?.Env, RegisteredAppId: coreContext?.Profile?.AppId }).then(r => {
                    if (r.Success) {
                        setConfig(r.Items.first())
                    }
                })
            }
        }
    }

    const renderDialog = () => {
        //Operation is executing.
        if (confirmingFranchiseeSetup) {
            return <DialogControl
                className={'with-icon'}
                openDialog={confirmingFranchiseeSetup}
                title={"Setting up the Franchisee Account"}
                disableXButton={true}
                disableCancel={true}
                disableOk={true}
            >
                <div className="dialog-text">
                    <p>This may take a few days.</p>
                    <br />
                    <p>Please remember to create an "Account Setup" ticket within the <a href='https://dev.azure.com/ABMFG' target='_blank'>ABMFG ADO environment</a>.</p>
                    <br />
                    <p>Once the ticket is assigned to you and marked as closed, return to the Wizard to continue your setup.</p>
                </div>
            </DialogControl>
        } else if (error === true) {
            //Operation finished executing and we know if it is successful or not.
            return <DialogControl
                className={'with-icon'}
                openDialog={error}
                title={[<IconControl className={'error'} iconKey={'error'} />, "Error"]}
                okText={"Return"}
                disableCancel={true}
                onOk={async () => {
                    setError(false)
                }}
            >
                <div className="dialog-text error">
                    Some of the information entered doesn’t look quite right. Please double-check the fields and ensure all entries match the correct format. If you need help, check the guidelines or contact support for assistance.
                </div>
            </DialogControl>
        } else if (openSuccessDialog) {
            //Operation finished executing and we know if it is successful or not.
            return <DialogControl
                className={'with-icon'}
                openDialog={openSuccessDialog}
                title={[<IconControl className="success" iconKey={'success'} />, "Success"]}
                okText={"Check It Out"}
                cancelText={"Cancel"}
                onCancel={() => {
                    setOpenSuccessDialog(false)
                }}
                onOk={async () => {

                    window.open(clientItem.ClientDomains.first().Domain, '_blank');
                    setOpenSuccessDialog(false)
                }}>
                <div className="dialog-text">
                    <p>
                        The Franchisee Account has been set up.
                    </p>
                    <p>
                        Please check out the Platform and make sure everything looks good.
                    </p>
                </div>
            </DialogControl>
        } else if (openInviteDialog) {
            //Operation finished executing and we know if it is successful or not.
            return <DialogControl
                className={'with-icon'}
                openDialog={openInviteDialog}
                title={[<IconControl className="success" iconKey={'success'} />, "Finalize Setup"]}
                okText={"Send Invites"}
                cancelText={"Skip"}
                disableOk={!selectedEmailTemplate}
                onCancel={async () => {

                    setOpenInviteDialog(false)
                    setCurrentStep(2)
                }}
                onOk={async () => {
                    await emailReqMgr.RunOperation({
                        operationName: "SendOnboardingEmails",
                        queryParams: {
                            e: onboardingItem.IntakeEmails,
                            et: selectedEmailTemplate,
                            cid: onboardingItem.ClientId,
                            emailType: "1"
                        }
                    })
                    setCurrentStep(2)
                    setOpenInviteDialog(false)
                }}>
                <div className="dialog-text">
                    <p>Choose an email template and send out emails to the Intake team to assist with the intake process.</p>
                    {
                        emailTemplateMgr.selectData?.find(x => x.DataOperationItem === "EmailTemplate") ?
                            <FieldControl
                                fieldMetadata={{
                                    FieldType: SystemTypes.UIFieldType.SearchSelectList,
                                    DataJson: emailTemplateMgr.selectData?.find(x => x.DataOperationItem === "EmailTemplate")?.jsondata ?? [],
                                    FieldName: "EmailTemplate"
                                }}
                                value={selectedEmailTemplate}
                                onChange={(v, fn) => {
                                    setSelectedEmailTemplate(v)
                                }}
                            />
                            : <Loading type={'spinner-2'} />
                    }
                </div>

            </DialogControl>
        }
    }
    return (
        <>
            {renderDialog()}
            <div className="wizard-content">
                <div className='options-container'>
                    <div className="left-col">
                        <h4>
                            Tenant Mode
                        </h4>
                        <div className="option disabled">
                            <label>
                                <input type='radio' name="portal" value="multi" onChange={handleOptionChange} disabled={true} />
                                Multi-tenant
                            </label>
                        </div>
                        <div className="option">
                            <label>
                                <input type='radio' name="portal" value="single" checked="checked" onChange={handleOptionChange} />
                                Single Tenant
                            </label>
                        </div>

                        {!isLoading ? configFieldNames.map((x, i) => {
                            const fieldMetadata = configMgr.uiMetadata.first().Fields?.find(y => y.FieldName === x)
                            return <FieldControl
                                key={i}
                                groupId={"config"}
                                value={config[x]}
                                fieldMetadata={fieldMetadata}
                                onChange={handleOnChange} />
                        }) : <Loading type={'spinner-2'} />
                        }
                    </div>
                    <div className="right-col">
                        <h4>Instructions</h4>
                        <p>The instructions for how to obtain and securely share the IDs and Secrets has already been shared with the Franchisee’s IT Support Team (see Intake – Section 3.1). </p>
                        <br />
                        <p>Once that information has been received, the information to the left is to be completed for the IDs and Secret information.</p>
                    </div>
                </div>
            </div>
            <WizardFooter
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentTab(3)}
                disableAll={isLoading || confirmingFranchiseeSetup}
                showSecBtn={!onboardingItem?.SystemConfigurationCompleted}
                primaryBtnIsLoading={onboardingItem?.SystemConfigurationCompleted === false}
                primaryBtnOnClick={async () => {
                    if (onboardingItem?.SystemConfigurationCompleted) {
                        setOpenInviteDialog(true)
                        emailTemplateMgr.GetSelectData({
                            DisplayName: "EmailTemplate",
                            DataOperationItem: "EmailTemplate",
                        })
                        if (onboardingItem.Step === 1) {
                            const { filledFields, totalFields } = handleProgressUpdate()
                            await handleProgress(filledFields, totalFields, 'page14', updatedPageValues => {
                                updateOnboardingProcess(2, updatedPageValues);
                            });
                        }
                    } else {
                        await handleOnSave(true)
                    }
                }}
                secBtnOnClick={async () => {
                    await handleOnSave()
                }}
                primaryBtnText={onboardingItem?.SystemConfigurationCompleted ? "Confirm Setup" : "Complete Setup"}
                secBtnText={"Save"} />
        </>
    )
}

export default SystemConfiguration