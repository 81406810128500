import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import MultiSelectTreeControl from '../../../components/MultiSelectTreeControl'
import { Loading } from 'spark-core-dx/components'
import { useWizardDomainContext } from '../WizardDomainProvider';
import WizardFooter from './components/WizardFooter';
import { useGenericManager } from 'spark-core-dx/hooks'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';

const FranchiseeOptions = () => {
    const { currentStep, setCurrentStep, handleProgress, updateOnboardingProcess, onboardingItem } = useWizardDomainContext();
    const { cid } = useParams();

    const itemService = new ItemServiceFactory()
    const externalAppMgr = useGenericManager(itemService.ItemServiceEnum.ExternalApp, itemService);
    const franchiseeExternalAppSettingMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeExternalAppSetting, itemService);

    const [franchiseeOptions, setFranchiseeOptions] = useState([])

    const [selectedNodes, setSelectedNodes] = useState(['BuildOps', 'Sage', 'Reporting (SSRS)', 'ValueBuilder']);
    const [francEASItems, setFrancEASItems] = useState([])
    const [allFEASItems, setAllFEASItems] = useState([])

    const [disabledList, setDisabledList] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (franchiseeOptions?.length > 0) {
            setIsLoading(false)
        }
    }, [franchiseeOptions])

    useEffect(() => {
        externalAppMgr.search({}).then(r => {
            setFranchiseeOptions(r)
        })
        //  && x.IsActive == true && x.IsDeleted == false
        franchiseeExternalAppSettingMgr.search({ searchModel: `x => x.ClientId == cid`, params: { cid: cid } }).then(r => {
            setAllFEASItems(r)
            setFrancEASItems(r.filter(x => x.IsActive && !x.IsDeleted))
        })
    }, [])

    const handleProgressUpdate = () => {
        let filledFields = 0;
        let totalFields = 1;

        if (selectedNodes?.length > 0) filledFields++
        return { filledFields, totalFields }
        // handleProgress(filledFields, totalFields, 'page4');
    }

    useEffect(() => {
        if (francEASItems?.length > 0) {
            const populateSelectedNodes = () => {
                const selectedApps = francEASItems?.map(object => {
                    const appList = franchiseeOptions?.find(app => app.UniqueId === object.ExternalAppId);
                    return appList ? appList.Name : null;
                }).filter(name => name !== null);

                return selectedApps
            }
            let selectedApps = populateSelectedNodes()
            setSelectedNodes(selectedApps)
            if (onboardingItem.Step > 6) {
                setDisabledList(selectedApps)
            }
        }
    }, [francEASItems, franchiseeOptions, onboardingItem.Step])

    const mapSelectedNodesToItems = () => {
        const mappedItems = selectedNodes?.map(nodeName => {
            const existingItem = allFEASItems?.find(item => {
                const app = franchiseeOptions?.find(option => option.UniqueId === item.ExternalAppId);
                return app && app.Name === nodeName;
            });

            if (existingItem) {
                return {
                    ...existingItem,
                    IsActive: true,
                }
            } else {
                const foundNode = franchiseeOptions.find(option => option.Name === nodeName);
                if (foundNode) {
                    return {
                        ClientId: foundNode.ClientId || cid,
                        ExternalAppId: foundNode.UniqueId,
                    };
                }
                return null;
            }
        }).filter(item => item !== null);

        const deletedItems = allFEASItems?.filter(item => {
            const app = franchiseeOptions?.find(option => option.UniqueId === item.ExternalAppId);
            return app && !selectedNodes?.includes(app.Name);
        }).map(item => ({
            ...item,
            IsActive: false,
        }));

        return [...mappedItems, ...deletedItems];
    };

    const handleSave = async (isContinue) => {
        const francEASItemArray = mapSelectedNodesToItems()
        const { filledFields, totalFields } = handleProgressUpdate()

        try {
            for (const item of francEASItemArray) {
                await franchiseeExternalAppSettingMgr.saveItem(item)
            }
            if (isContinue) {
                setCurrentStep(currentStep + 1)
                await handleProgress(filledFields, totalFields, 'page4', updatedPageValues => {
                    updateOnboardingProcess(currentStep + 1, updatedPageValues);
                });
            } else
                await handleProgress(filledFields, totalFields, 'page4', updatedPageValues => {
                    updateOnboardingProcess(currentStep, updatedPageValues);
                });
            return true;
        }
        catch (error) {
            return false;
        }

    }

    const handlePrev = () => {
        setCurrentStep(currentStep - 1)
    }

    return (<>
        <div className="wizard-content">
            {
                !isLoading ?
                    <>
                        <h4>Configuration options:</h4>
                        <MultiSelectTreeControl
                            arrayOfObj={franchiseeOptions}
                            disabledList={disabledList}
                            selectedNodes={selectedNodes}
                            setSelectedNodes={setSelectedNodes}
                            expanded={['System of Records', 'Applications', 'Warehouse and Reporting', 'Legacy Systems']}
                        />
                    </>

                    :
                    <Loading type={'spinner-2'} />
            }
            {/* <NewSelectTreeControl arrayOfObj={franchiseeOptions} onChange={(e) => console.log(e.target.value)} /> */}
        </div>
        <WizardFooter
            disableAll={isLoading}
            showPrevBtn={true}
            prevBtnOnClick={handlePrev}
            showSecBtn={true}
            primaryBtnOnClick={async () => await handleSave(true)}
            secBtnOnClick={async () => await handleSave()}
            primaryBtnText={"Save & Continue"}
            secBtnText={"Save"} />
    </>

    )
}

export default FranchiseeOptions