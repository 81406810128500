import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'spark-core-dx/components';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const PublicDomain = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};

export default PublicDomain;