import React, { useEffect, useState } from 'react'
import WizardFooter from './WizardFooter'
import { Loading } from 'spark-core-dx/components'
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { useValidationErrors } from 'spark-core-dx/hooks'

const WarehouseAndReporting = ({ applicationList, setCurrentTab }) => {
  const { handleProgress, setCurrentStep, currentStep, updateOnboardingProcess } = useWizardDomainContext();
  const { hasAndShowValidationErrors, getValidationErrors } = useValidationErrors();

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Set conditional for loading here.
    setIsLoading(false)
  }, [])

  const handleSave = async (isContinue) => {
    if (!hasAndShowValidationErrors('')) {
      if (isContinue) {
        setCurrentTab(4)
      }
      await handleProgress(1, 1, 'page73', updatedPageValues => {
        updateOnboardingProcess(currentStep, updatedPageValues);
      });
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div className="wizard-content">
        {
          !isLoading ? 'Coming soon..' : <Loading type={'spinner-2'} />
        }
      </div>
      <WizardFooter
        disableAll={isLoading}
        showPrevBtn={true}
        prevBtnOnClick={() => setCurrentTab(2)}
        showSecBtn={true}
        primaryBtnOnClick={async () => await handleSave(true)}
        secBtnOnClick={async () => await handleSave()}
        primaryBtnText={"Save & Continue"}
        secBtnText={"Save"} />
    </>
  )
}

export default WarehouseAndReporting