import React, { useState, useEffect } from 'react'
import AppCard from './AppCard'
import './apps.scss'
import { ShadowOnScroll, IconControl } from 'spark-core-dx/components';

const Apps = ({ appList, setOpenApps }) => {
    const [search, setSearch] = useState('')
    const [filteredList, setFilteredList] = useState(appList)

    useEffect(() => {
        const filtList = appList?.filter(app => {
            const matchSearch = Object.values(app).some(value =>
                value && value.toString().toLowerCase().includes(search.toLowerCase()));
            return matchSearch
        })
        setFilteredList(filtList)
    }, [appList, search])

    return (
        <div className='app-popup'>
            <IconControl iconKey={"close"} className='close-icon' onClick={() => setOpenApps(false)} />

            <div className="search-wrapper">
                <IconControl className={'search-icon'} iconKey={'search'} />
                <input placeholder='Search..' type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            <ShadowOnScroll className="app-list-wrapper">
                <div className='app-list'>
                    {filteredList.map((app, appIndex) => (
                        <AppCard key={appIndex} src={app.ImageUrl} appName={app.Name} disabled={app.disabled} />
                    ))}
                </div>
            </ShadowOnScroll>
        </div>
    )
}

export default Apps