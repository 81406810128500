import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { CheckboxControl, FieldControl } from 'spark-core-dx/components';
import { useCoreContext } from 'spark-core-dx/contexts'
import { useGenericManager, useValidationErrors } from 'spark-core-dx/hooks';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import WizardFooter from './WizardFooter';

const Branding = ({ themeItem, setThemeItem, setCurrentTab }) => {
    const { cid } = useParams();
    const coreContext = useCoreContext();
    const itemService = new ItemServiceFactory()
    const { clientItem, setClientItem, handleProgress, updateOnboardingProcess, onboardingItem } = useWizardDomainContext();
    const { hasAndShowValidationErrors } = useValidationErrors();
    const themeMgr = useGenericManager(itemService.ItemServiceEnum.Theme, itemService);
    const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);

    const [colorSelection, setColorSelection] = useState({})
    const [domainCheckbox, setDomainCheckbox] = useState({
        1: { value: true, disabled: true },
        2: { value: false, disabled: false },
    })

    const [domainsList, setDomainsList] = useState([])
    const [customURL, setCustomURL] = useState(null)

    useEffect(() => {
        if (clientItem?.ClientDomains?.length > 0) {
            const renderDomainsList = clientItem?.ClientDomains?.map((dom) => dom.Domain)

            const containsAbmfg = renderDomainsList.some(dom => dom.includes("abmfg.com"));
            const containsNonAbmfg = renderDomainsList.find(domain => !domain.includes("abmfg.com"));

            const updatedDomainCheckbox = {
                1: { value: containsAbmfg, disabled: true },
                2: { value: containsNonAbmfg, disabled: onboardingItem.SystemConfigurationCompleted !== null }
            };

            // Disables checkbox because you're not allowed to update domain after setting it.

            if (containsNonAbmfg) {
                setCustomURL(containsNonAbmfg)
            }
            setDomainsList(renderDomainsList);
            setDomainCheckbox(updatedDomainCheckbox);
        }
    }, [clientItem?.ClientDomains])

    useEffect(() => {
        const updatedDomainsList = [];
        if (domainCheckbox[1].value) {
            updatedDomainsList.push(`https://${coreContext.Launch?.Env === "PROD" ? "" : coreContext.Launch?.Env?.toLowerCase() + "."}${clientItem?.ShortName.toLowerCase()}.abmfg.com`);
        }
        if (domainCheckbox[2].value && customURL) {
            updatedDomainsList.push(customURL);
        }
        setDomainsList(updatedDomainsList);

    }, [domainCheckbox, customURL]);


    useEffect(() => {
        if (themeItem?.SparkAppStyleSheet) {
            const primaryMatch = themeItem?.SparkAppStyleSheet.match(/--primary:\s*([^;]+);/);
            const secondaryMatch = themeItem?.SparkAppStyleSheet.match(/--secondary:\s*([^;]+);/);
            const tertiaryMatch = themeItem?.SparkAppStyleSheet.match(/--tertiary:\s*([^;]+);/);
            const primary = primaryMatch ? primaryMatch[1].trim() : "#00356B";
            const secondary = secondaryMatch ? secondaryMatch[1].trim() : "#29ABE2";
            const tertiary = tertiaryMatch ? tertiaryMatch[1].trim() : "#D8D8D8";
            setColorSelection({ primary, secondary, tertiary })
        }
    }, [themeItem])

    const handleProgressUpdate = () => {
        const primaryCount = colorSelection.primary ? 1 : 0;
        const portalDomainCount = (domainsList.length > 0)
        const totalFilled = primaryCount + portalDomainCount;
        const totalCount = 2

        return { totalFilled, totalCount }
        // handleProgress(totalFilled, totalCount, 'page13');
    }

    const createDomainItemsArray = (domainsList) => {
        // UniqueId is made from the url, appId, and environment so don't need to grab old item to update.
        return domainsList.map((url) => ({
            RegisteredAppId: coreContext?.Profile?.AppId,
            Environment: 'QA',
            Domain: url,
        }));
    };

    const handleCustomValidate = (value) => {
        const urlPattern = /^https:\/\/([\w-]+(\.[\w-]+)+)([/?].*)?$/;
        if (!urlPattern.test(value)) {
            return `Please enter a valid URL (beginning with 'https://').`;
        } else return true
    }

    const handleColor = (type, value) => {
        setColorSelection(prev => {
            return { ...prev, [type]: value }
        })
    }

    const handleOnSave = async (isContinue) => {
        if (!hasAndShowValidationErrors()) {
            const { primary, secondary, tertiary } = colorSelection;

            const updatedStyleSheet = `.app {
                --primary: ${primary || "#00356B"};
                --secondary: ${secondary || "#29ABE2"};
                --tertiary: ${tertiary || "#D8D8D8"};
                }`;
            const copiedTheme = {
                ...themeItem,
                Name: `${clientItem.Name}'s Theme`
            }
            copiedTheme.SparkAppStyleSheet = updatedStyleSheet;
            copiedTheme.ClientId = cid;

            const domainItemsArray = createDomainItemsArray(domainsList);

            if (clientItem) {
                // Only save if there's 2 or less domains.
                let copiedClient;
                copiedClient = {
                    ...clientItem,
                    ClientDomains: domainItemsArray
                }

                await clientMgr.saveItem(copiedClient).then(r => {
                    if (r.Success) {
                        let newClient = r.Items.first()
                        setClientItem(newClient)
                    }
                })
            }

            await themeMgr.saveItem(copiedTheme).then(r => {
                if (r.Success) {
                    setThemeItem(r.Items.first())
                    if (isContinue) {
                        setCurrentTab(4)
                    }
                }
            })
            const { totalFilled, totalCount } = handleProgressUpdate()
            await handleProgress(totalFilled, totalCount, 'page13', updatedPageValues => {
                updateOnboardingProcess(1, updatedPageValues);
            });
        }
    }

    const renderColors = () => {
        return (
            <div className="branding-colors">
                <FieldControl
                    key={"primary"}
                    fieldMetadata={{
                        FieldType: 6,
                        FieldName: "Primary",
                        DisplayName: "Primary",
                        IsRequired: true,
                    }}
                    validationMessage={'Please select a primary color.'}
                    value={colorSelection['primary']}
                    onChange={(value) => handleColor('primary', value)}
                />
                <FieldControl
                    key={"secondary"}
                    fieldMetadata={{
                        FieldType: 6,
                        FieldName: "Secondary",
                        DisplayName: "Secondary",
                        IsRequired: false,
                    }}
                    validationMessage={'Please select a secondary color.'}
                    value={colorSelection['secondary']}
                    onChange={(value) => handleColor('secondary', value)}
                />
                <FieldControl
                    key={"tertiary"}
                    fieldMetadata={{
                        FieldType: 6,
                        FieldName: "Tertiary",
                        DisplayName: "Tertiary",
                        IsRequired: false,
                    }}
                    validationMessage={'Please select a tertiary color.'}
                    value={colorSelection['tertiary']}
                    onChange={(value) => handleColor('tertiary', value)}
                />
            </div>
        )
    }

    const renderPortalDomain = () => {
        return (
            <div className="branding-portal">
                <div className="warning">Once the portal domain is established, it cannot be changed via the wizard process. If changes are needed, then a ticket will need to be submitted.</div>
                <div className='domain-container'>
                    <div className="option">
                        <CheckboxControl
                            value={domainCheckbox[1].value}
                            disabled={domainCheckbox[1].disabled}
                            onChange={() => setDomainCheckbox({
                                ...domainCheckbox,
                                1: { ...domainCheckbox[1], value: !domainCheckbox[1].value }
                            })}
                        />
                        ABMFG Subdomain
                    </div>
                    {domainCheckbox[1].value &&
                        <div className="url-placeholder">
                            <input type="text" disabled={true} />
                            <span>https://{coreContext.Launch?.Env === "PROD" ? "" : coreContext.Launch?.Env?.toLowerCase() + "."}{clientItem?.ShortName.toLowerCase()}.abmfg.com</span>
                        </div>
                    }
                    <div className="option">
                        <CheckboxControl
                            value={domainCheckbox[2].value}
                            disabled={domainCheckbox[2].disabled}
                            onChange={() => setDomainCheckbox({
                                ...domainCheckbox,
                                2: { ...domainCheckbox[2], value: !domainCheckbox[2].value }
                            })}
                        />
                        Custom URL
                    </div>
                    {domainCheckbox[2].value &&
                        <FieldControl
                            key={'custom-subdomain'}
                            fieldMetadata={{
                                FieldType: 1,
                                FieldName: 'CustomSubdomain',
                                IsRequired: true,
                            }}
                            disabled={domainCheckbox[2].disabled}
                            // This disabled changed the opacity of the textbox. 
                            value={customURL ?? 'https://'}
                            onChange={setCustomURL}
                            validationMessage={`Custom URL (beginning with 'https://') is required.`}
                            onCustomValidate={handleCustomValidate}
                        />}
                </div>
            </div>
        )
    }

    return (
        <>

            <div className="wizard-content">
                <h4>
                    Colors
                </h4>
                {renderColors()}
                <div className='title-with-subtitle'>
                    <h4>
                        Portal Domain
                    </h4>
                    <p>Select a portal domain. </p>
                </div>
                {renderPortalDomain()}
            </div>
            <WizardFooter
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentTab(2)}
                showSecBtn={true}
                primaryBtnOnClick={async () => { await handleOnSave(true) }}
                secBtnOnClick={async () => await handleOnSave()}
                primaryBtnText={"Save & Continue"}
                secBtnText={"Save"} />
        </>
    )
}

export default Branding