import React, { useState, useRef, useEffect } from "react";
import { IconControl, CheckboxControl } from 'spark-core-dx/components';
import "./TreeNode.scss"

const TreeNode = ({ node, selectedNodes, handleSelect, level = 0, expandedNodes, handleExpand }) => {
  const hasChildren = node.child && node.child.length > 0;
  const isExpanded = expandedNodes.includes(node.name);
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight('0px');
    }
  }, [isExpanded]);


  const determineCheckboxValue = (node) => {
    if (!hasChildren) {
      return selectedNodes?.includes(node.name);
    }

    // Nodes are filtered out if they're disabled. If they're disabled but are selected, they are included (previously selected pre Step 7). 
    const filteredChildren = node.child.filter(c => !c.disabled || (selectedNodes?.includes(c.name) && c.disabled))

    const allChildrenSelected = filteredChildren.length === 0 ? false : filteredChildren.every((child) => selectedNodes?.includes(child.name));
    const someChildrenSelected = filteredChildren.some((child) => (selectedNodes?.includes(child.name) || (child.child && determineCheckboxValue(child) === 'indeterminate'))
    );

    if (allChildrenSelected) {
      return true;
    } else if (someChildrenSelected) {
      return 'indeterminate';
    } else {
      return false;
    }
  };

  const toggleExpand = (e) => {
    e.stopPropagation();
    handleExpand(node);
  };

  const toggleSelect = (e) => {
    e.stopPropagation();
    handleSelect(node);
  };

  return (
    <div className='select-tree-option-container'>
      <div className={`select-tree-option`} onClick={!node.disabled ? toggleSelect : null} style={{ paddingLeft: `${1.5 * level}rem` }}>
        {hasChildren ? (
          <span className='select-tree-option-icon' onClick={toggleExpand}>
            <IconControl iconKey={"angleDown"} className={isExpanded ? '' : 'open'} style={{ height: '1.25rem', width: '1.25rem' }} />
          </span>
        ) :
          <div className='select-tree-option-placeholder' />
        }
        <div className={`select-tree-option-text`} style={!node.disabled ? { cursor: `pointer` } : {}}>
          <div onClick={(e) => e.stopPropagation()}>
            <CheckboxControl
              value={determineCheckboxValue(node)}
              onChange={toggleSelect}
              disabled={`${node.disabled ? ' disabled' : ''}`}
            />
          </div>
          <span className={`${node.disabled ? ' disabled' : ''}`}>{node.name}</span>
        </div>
      </div>
      {hasChildren && (
        <div className="select-tree-option-children"
          ref={contentRef}
          style={{ maxHeight: maxHeight }}>
          {node.child.map((childNode) => (
            <TreeNode
              key={childNode.id}
              node={childNode}
              selectedNodes={selectedNodes}
              handleSelect={handleSelect}
              level={level + 1}
              expandedNodes={expandedNodes}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeNode