import React from 'react';
import { ButtonControl } from 'spark-core-dx/components'
const WizardFooter = (
    {
        showPrevBtn,
        prevBtnOnClick,
        showExportBtn,
        exportBtnOnClick,
        showSecBtn,
        disablePrimaryBtn,
        primaryBtnText,
        primaryBtnOnClick,
        secBtnOnClick,
        secBtnText,
        disableAll,
        disablePrevBtn,
        disableExportBtn,
        disableSecBtn
    }
) => {

    return (
        <div className="wizard-buttons">
            <div className='wizard-buttons__left'>
                {showPrevBtn && <ButtonControl className={'form-footer-btn'} minWidth={'7rem'} disabled={disableAll ?? disablePrevBtn} value={'Previous'} type={'cancel'} onClick={prevBtnOnClick} />}
            </div>
            <div className='wizard-buttons__right'>
                {showExportBtn && <ButtonControl key={'1'} className={'form-footer-btn'} disabled={disableAll ?? disableExportBtn} minWidth={'7rem'} value={'Export'} type={'cancel'} onClick={exportBtnOnClick} />}
                {showSecBtn && <ButtonControl key={'2'} className={'form-footer-btn'} disabled={disableAll ?? disableSecBtn} minWidth={'7rem'} value={secBtnText} type={'cancel'} onClick={secBtnOnClick} />}
                <ButtonControl className={'form-footer-btn'} minWidth={'9rem'} disabled={disableAll ?? disablePrimaryBtn} value={primaryBtnText} type={'okay'} onClick={primaryBtnOnClick} />
            </div>
        </div>
    );
};

export default WizardFooter;