/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import { ItemService } from 'spark-core-dx/services'

export const Operations = {
    EntityMasterRecordItem: {
        LookupMasterEntityObject: "LookupMasterEntityObject",
    },
    EntityObjectItem: {
        MapEntityObject: "MapEntityObject",
        SearchEntityObject: "SearchEntityObject",
    },
    SourceObjectItem: {
        LoadSourceObjectKeys: "LoadSourceObjectKeys",
        SearchSourceObject: "SearchSourceObject",
    },
    SourceSystemItem: {
        LoadSourceObjects: "LoadSourceObjects",
        LoadSourceSystemTargets: "LoadSourceSystemTargets",
    },
    WarehouseItem: {
        WarehouseDeploy: "WarehouseDeploy",
        WarehouseDrop: "WarehouseDrop",
        WarehouseSync: "WarehouseSync",
        WarehouseUpgrade: "WarehouseUpgrade",
        WarehouseCheck: "WarehouseCheck",
        WarehouseRecordCheck: "WarehouseRecordCheck",
    },
}

export class BOAddressItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOAddress", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOAssetItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOAsset", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOAssetTypeItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOAssetType", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOCustomerItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOCustomer", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOCustomerPropertyItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOCustomerProperty", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOCustomerTagItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOCustomerTag", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOEmployeeItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOEmployee", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOInventoryProductItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOInventoryProduct", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOInvoiceItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOInvoice", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOJobItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOJob", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOJobTypeItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOJobType", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOLaborRateGroupsItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOLaborRateGroups", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOLaborTypeItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOLaborType", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOPayrollHourTypeItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOPayrollHourType", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOProductItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOProduct", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOProjectItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOProject", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOPurchaseOrderItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOPurchaseOrder", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOPurchaseOrderReceiptItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOPurchaseOrderReceipt", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOQuoteItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOQuote", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOVendorItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOVendor", coreState, coreContext, "APP", null, isWarning);
    }

}

export class BOVisitItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/BOVisit", coreState, coreContext, "APP", null, isWarning);
    }

}

export class ConvokeAppConfigItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/ConvokeAppConfig", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class DataTypeItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/DataType", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class EntityMasterRecordItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/EntityMasterRecord", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class EntityMasterRecordMapItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/EntityMasterRecordMap", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class EntityObjectFieldMapItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/EntityObjectFieldMap", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class EntityObjectItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/EntityObject", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class EntityQueryItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/EntityQuery", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class SchemaItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Schema", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class IntegrationItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Integration", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class SourceObjectItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/SourceObject", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class SourceObjectRecordItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/SourceObjectRecord", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class SourceSystemItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/SourceSystem", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class DirtyRecordDeploymentItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/DirtyRecordDeployment", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class SchemaAPIItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/SchemaAPI", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class SchemaDestinationLogItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/SchemaDestinationLog", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class WarehouseItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Warehouse", coreState, coreContext, "CONVOKE", null, isWarning);
    }

}

export class AgreementNumberItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/AgreementNumber", coreState, coreContext, "APP", null, isWarning);
    }

}

export class ExternalAppItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/ExternalApp", coreState, coreContext, "APP", null, isWarning);
    }

}

export class FranchiseeExternalAppSettingItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/FranchiseeExternalAppSetting", coreState, coreContext, "APP", null, isWarning);
    }

}

export class FranchiseeLocationItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/FranchiseeLocation", coreState, coreContext, "APP", null, isWarning);
    }

}

export class OnboardingItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Onboarding", coreState, coreContext, "APP", null, isWarning);
    }

}

export class ProposalNumberItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/ProposalNumber", coreState, coreContext, "APP", null, isWarning);
    }

}





