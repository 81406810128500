import React, { useEffect, useState } from 'react'
import { useGenericManager } from 'spark-core-dx/hooks'
import { Loading } from 'spark-core-dx/components'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory'
import { useParams } from 'react-router-dom';
import { useWizardDomainContext } from '../WizardDomainProvider';
import SystemOfRecords from './components/SystemOfRecords';
import LegacySystems from './components/LegacySystems';
import Application from './components/Application';
import WarehouseAndReporting from './components/WarehouseAndReporting';

const SystemApplicationSetup = () => {
    const { } = useWizardDomainContext();
    const { cid } = useParams();

    const itemService = new ItemServiceFactory()
    const externalAppMgr = useGenericManager(itemService.ItemServiceEnum.ExternalApp, itemService);
    const franchiseeExternalAppSettingMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeExternalAppSetting, itemService);
    const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);

    const [franchiseeOptions, setFranchiseeOptions] = useState([])
    const [francEASItems, setFrancEASItems] = useState([])

    const [applicationList, setApplicationList] = useState({})
    const [currentTab, setCurrentTab] = useState(0);
    const [config, setConfig] = useState(null)

    useEffect(() => {
        grabData();
    }, [])

    const grabData = async () => {
        externalAppMgr.search({}).then(r => {
            setFranchiseeOptions(r)
        })
        franchiseeExternalAppSettingMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(r => {
            setFrancEASItems(r)
        })
        configMgr.Query({ model: "x => x.IsActive == true && x.IsDeleted == false && x.ClientId == cid", params: { cid: cid } }).then(r => {
            if (r.Success) {
                const resultedConfig = r.Items.first();
                setConfig(resultedConfig)
            }
        })

    }

    useEffect(() => {
        const mapApplicationsByCategory = () => {
            const categoryMap = {};

            francEASItems.forEach((object) => {
                const appList = franchiseeOptions.find(app => app.UniqueId === object.ExternalAppId);
                if (appList && appList.CategoryName) {
                    if (!categoryMap[appList.CategoryName]) {
                        categoryMap[appList.CategoryName] = [];
                    }
                    categoryMap[appList.CategoryName].push(appList.Name);
                }
            });

            return categoryMap;
        }
        const appList = mapApplicationsByCategory()
        setApplicationList(appList)
        setCurrentTab(1)
    }, [francEASItems, franchiseeOptions])

    const renderSteps = () => {
        switch (currentTab) {
            case 1: {
                return <SystemOfRecords config={config} setConfig={setConfig} applicationList={applicationList['System of Records']} setCurrentTab={setCurrentTab} />
            }
            case 2: {
                return <Application applicationList={applicationList['Application']} setCurrentTab={setCurrentTab} />;
            }
            case 3: {
                return <WarehouseAndReporting applicationList={applicationList['Warehouse and Reporting']} setCurrentTab={setCurrentTab} />;
            }
            case 4: {
                return <LegacySystems applicationList={applicationList['Legacy Systems']} setCurrentTab={setCurrentTab} />
            }
        }
    }

    return (
        <>
            <div className="wizard-tabs">
                {["System of Records", "Application", "Warehouse and Reporting", "Legacy Systems"].map((tab, index) => {
                    return (
                        <div key={index}
                            className={`wizard-tab` + ((currentTab === index + 1) ? ' active-tab' : '')}
                            onClick={() => setCurrentTab(index + 1)}>
                            {tab}
                        </div>
                    )
                })}
            </div>
            {renderSteps()}
        </>
    )
}

export default SystemApplicationSetup