import React from 'react'
import '../Reports.scss'
import { ReportingIFrame } from './ReportingIFrame';


const Reports = ({ }) => {    
    return (
        <div className="reports">
            <ReportingIFrame/>
        </div>
    )
}

export default Reports;



