import React, { useEffect, useState } from 'react'
import { useGenericManager } from 'spark-core-dx/hooks'
import { Loading } from 'spark-core-dx/components'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory'
import { useParams } from 'react-router-dom';
import { useWizardDomainContext } from '../WizardDomainProvider';
import Logos from './components/Logos';
import Branding from './components/Branding';
import SystemConfiguration from './components/SystemConfiguration';
import CompanyInformation from './components/CompanyInformation';
import SetupUsers from './SetupUsers';
import { useCoreContext } from 'spark-core-dx/contexts';

const FranchiseeSetup = () => {
  const { cid } = useParams();
  const coreContext = useCoreContext()
  const itemService = new ItemServiceFactory()
  const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);
  const themeMgr = useGenericManager(itemService.ItemServiceEnum.Theme, itemService);
  const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);
  const { setClientItem, clientItem, updateOnboardingProcess, onboardingItem } = useWizardDomainContext();
  const [currentTab, setCurrentTab] = useState((onboardingItem.SystemConfigurationCompleted !== null && onboardingItem.Step === 1) ? 4 : 1)
  const [themeItem, setThemeItem] = useState(null)

  useEffect(() => {
    grabData();
  }, [cid, clientItem])

  const grabData = async () => {
    if (cid) {
      themeMgr.search({ searchModel: `x => x.ClientId == cid`, params: { cid: cid } }).then(r => {
        if (r?.first()) {
          setThemeItem(r.first())
        }
      })

    }

    if (!clientMgr.uiMetadata)
      clientMgr.GetMetadata({ appId: coreContext.Profile.AppId })

    if (!themeMgr.uiMetadata)
      themeMgr.GetMetadata({ appId: coreContext.Profile.AppId })
  }

  const renderSteps = () => {
    switch (currentTab) {
      case 1: {
        return clientMgr?.uiMetadata ? <CompanyInformation setCurrentTab={setCurrentTab} /> : <Loading type={'spinner-2'} />
      }
      case 2: {
        return <Logos setCurrentTab={setCurrentTab} themeItem={themeItem} setThemeItem={setThemeItem} />
      }
      case 3: {
        return <Branding setCurrentTab={setCurrentTab} setThemeItem={setThemeItem} themeItem={themeItem} />
      }
      case 4: {
        return <SystemConfiguration setCurrentTab={setCurrentTab} />
      }
    }
  }

  return (
    <>
      <div className="wizard-tabs">
        {["Company Information", "Logos", "Branding", "System Configuration"].map((tab, index) => {
          return (
            <div key={index}
              className={`wizard-tab` + ((currentTab === index + 1) ? ' active-tab' : '') + ((index !== 0 && !cid) ? " disabled" : '')}
              onClick={() => {
                if (cid !== null)
                  setCurrentTab(index + 1)
              }}>
              {tab}
            </div>
          )
        })}
      </div>
      {renderSteps()}
    </>

  )
}

export default FranchiseeSetup;