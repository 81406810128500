import React, { useState, useEffect, useRef } from 'react';
import { useAuthContext, useCoreContext, useCoreStateContext } from 'spark-core-dx/contexts';
import { useCoreRoute, useCorePermissions, useGenericManager } from 'spark-core-dx/hooks';
import './landing.scss'
import { NotificationList, IconControl, LoadingSkeleton } from 'spark-core-dx/components';
import AppCard from '../components/AppCard';
import ReportCard from '../components/ReportCard';
import Apps from '../components/Apps';
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';
const Landing = () => {
    const itemService = new ItemServiceFactory()
    const { isAuthorized } = useAuthContext();
    const { coreNavigate } = useCoreRoute();
    const { canView } = useCorePermissions();
    const coreContext = useCoreContext();
    const coreState = useCoreStateContext();
    const notificationMgr = useGenericManager('Notification');

    const profileMgr = useGenericManager(itemService.ItemServiceEnum.Profile)

    const carouselRef = useRef(null);
    const cardWidth = useRef(0);
    const visibleCards = useRef(0);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const [openApps, setOpenApps] = useState(false);

    const searchRef = useRef(null);
    const appRef = useRef(null);
    const appListRef = useRef(null);
    const pageRef = useRef(null);
    const [divWidth, setDivWidth] = useState('75%');
    const visibleCountRef = useRef(0);
    const [notifHeight, setNotifHeight] = useState('600px')
    const [currentIndex, setCurrentIndex] = useState(0);

    const [searchInputActive, setSearchInputActive] = useState(false)
    const [filterPopup, setFilterPopup] = useState(false); // popup for notifs on homepage?
    const [reports, setReports] = useState([])
    const [notifications, setNotifications] = useState();
    const [filteredNotifications, setFilteredNotifications] = useState();
    const [searchNotification, setSearchNotification] = useState('');

    const [visibleApps, setVisibleApps] = useState(null); // apps that are being showed
    const [visibleAppCount, setVisibleAppCount] = useState(null); // number of apps to show
    const [showSeeAllButton, setShowSeeAllButton] = useState(false); // if apps overflow, show button

    const notificationTypes = {
        0: <IconControl className="alert" iconKey={'alert'} />,
        2: <IconControl className="important" iconKey={'error'} />,
    }

    useEffect(() => {
        profileMgr.RunOperation({ operationName: "ProfileLanding" }).then(r => {
            if (r.Success) {
                setVisibleApps(r.Items.first().ExternalApps)
            }
        })
        const updateVisibleApps = () => {
            if (appListRef.current) {
                const cardElement = appListRef.current.querySelector('.app-card');
                if (cardElement) {

                    const cardWidth = cardElement.clientWidth + 14;

                    const seventyFive = pageRef.current.offsetWidth * 0.70;
                    const cardsPerRow = Math.floor(seventyFive / cardWidth);
                    const visibleCount = (cardsPerRow * 2) < 10 ? 10 : cardsPerRow * 2;
                    setVisibleAppCount(visibleCount);

                    if (visibleCount !== visibleCountRef.current) {
                        visibleCountRef.current = visibleCount;
                        setVisibleApps(prevApps => {
                            return prevApps?.slice(0, visibleCount)
                        });

                        const newDivWidth = (cardsPerRow * cardWidth) + 14;
                        setDivWidth(newDivWidth + 'px');
                    }
                }
            }
        };

        updateVisibleApps();
        window.addEventListener('resize', updateVisibleApps);

        return () => {
            window.removeEventListener('resize', updateVisibleApps);
        };
    }, []);

    useEffect(() => {
        const updateCardWidth = () => {
            if (carouselRef.current) {
                const firstCard = carouselRef.current.querySelector('.report-card');
                cardWidth.current = firstCard ? firstCard.clientWidth + 14 : 0;
                visibleCards.current = Math.floor(carouselRef.current.clientWidth / cardWidth.current);

                updateButtonStates(currentIndex);
            }
        };

        updateCardWidth();

        window.addEventListener('resize', updateCardWidth);
        return () => window.removeEventListener('resize', updateCardWidth);

    }, [currentIndex]);

    useEffect(() => {
        const adjustHeight = () => {
            if (appRef.current) {
                setNotifHeight(`${appRef.current.offsetHeight}px`)
            }
        };

        adjustHeight();
        window.addEventListener('resize', adjustHeight);

        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, [appRef?.current?.offsetHeight]);

    useEffect(() => {
        if (searchInputActive) {
            searchRef.current.focus()
        }
    }, [searchInputActive])

    useEffect(() => {
        if (isAuthorized) {
            let notifications = coreState?.get('Notification')?.filteredData.reverse()
            setNotifications(notifications)
            setFilteredNotifications(notifications)
        }
    }, [isAuthorized])

    useEffect(() => {
        const filteredNotifs = notifications?.filter(notif => {
            const matchSearch = Object.values(notif).some(value =>
                value && value.toString().toLowerCase().includes(searchNotification.toLowerCase()));

            return matchSearch
        })

        setFilteredNotifications(filteredNotifs)

    }, [searchNotification, notifications])

    useEffect(() => {
        setShowSeeAllButton(visibleApps?.length >= visibleAppCount || visibleApps?.length > 10)
    }, [visibleApps, visibleAppCount])

    const updateButtonStates = (newIndex) => {
        setIsAtStart(newIndex === 0);
        setIsAtEnd(newIndex + visibleCards.current >= reports.length);
    };

    const handleNext = () => {
        const newIndex = currentIndex + visibleCards.current;
        setCurrentIndex(newIndex);
        carouselRef.current.scrollLeft += cardWidth.current * visibleCards.current;
        updateButtonStates(newIndex);
    };

    const handlePrev = () => {
        const newIndex = Math.max(currentIndex - visibleCards.current, 0);
        setCurrentIndex(newIndex);
        carouselRef.current.scrollLeft -= cardWidth.current * visibleCards.current;
        updateButtonStates(newIndex);
    };

    if (isAuthorized) {
        return (
            <>
                {openApps &&
                    <Apps setOpenApps={setOpenApps} appList={visibleApps} />
                }
                <div className='landing-container' ref={pageRef}>
                    <div className={"landing-header"}>
                    <div className={"landing-img" + (coreContext?.ProfileEncodedImg ? "" : " no-img")}>
                        {coreContext.ProfileEncodedImg ? <img src={coreContext.ProfileEncodedImg} /> : <div>{coreContext?.Profile?.FirstName?.charAt(0).toUpperCase() + coreContext?.Profile?.LastName?.charAt(0).toUpperCase()}</div> }
                        </div>
                     <div className='landing-title'>{`Welcome, ${coreContext.Profile.FirstName} ${coreContext.Profile.LastName}!`}</div>
                    </div>

                    <div className='landing-second'>
                        <div className='landing-apps bubble' ref={appRef}>
                            <h3>Apps</h3>
                            <div className='app-list' ref={appListRef} style={{ width: divWidth }}>
                                {visibleApps ?
                                    visibleApps.map((app, appIndex) => (
                                        <AppCard key={appIndex} src={app.ImageUrl} appName={app.Name} disabled={app.IsEnabled} />
                                    ))
                                    : Array.from({ length: 10 }, (_, i) => <LoadingSkeleton key={i} className="app-card" width={'6rem'} height={'6rem'} borderRadius={".5rem"} />)
                                }
                            </div>
                            {showSeeAllButton && (
                                <div className='btn-more' onClick={() => setOpenApps(true)}>
                                    <p>See all apps</p>
                                    <IconControl iconKey={'arrowright'} />
                                </div>
                            )}
                        </div>
                        {canView("Notification") && <div className='landing-notifications bubble' style={{ height: notifHeight }}>
                            <div className="landing-notifications-header">
                                <h3>Announcements</h3>
                                <div className="notifications-search-wrapper">
                                    <IconControl iconKey={'search'} className='icon-item' onClick={() => setSearchInputActive(!searchInputActive)} />
                                    <input ref={searchRef} className={'table-search-input' + (searchInputActive ? " table-search-input-active" : "")} placeholder='Type to search...' type="text" value={searchNotification} onChange={(e) => setSearchNotification(e.target.value)} />
                                </div>
                            </div>
                            <NotificationList
                                filteredNotifications={filteredNotifications}
                                activeTab={'All'}
                                notificationTypes={notificationTypes}
                                setNotifications={setNotifications}
                                notificationMgr={notificationMgr} />
                        </div>}
                    </div>
                    {reports.length > 0 &&
                        <div className='landing-reports bubble'>
                            <div className='landing-reports-header'>
                                <h3>Favorite Reports</h3>
                                <div className='carousel-arrows'>
                                    <IconControl iconKey={'angleleft'} className={isAtStart ? " disabled" : ""} onClick={handlePrev} />
                                    <IconControl iconKey={'angleright'} className={isAtEnd ? " disabled" : ""} onClick={handleNext} />
                                </div>
                            </div>
                            < div className='reports-list carousel' ref={carouselRef}>
                                {reports?.map((report, index) => (
                                    <ReportCard
                                        key={index}
                                        src={profileMgr?.GetFileUrl(report.ReportImage)}
                                        reportTitle={report.Name}
                                        reportDate={report.CreatedDate}
                                        reportDescription={report.Description}
                                    />
                                ))}
                            </div>
                            <div className='btn-more' onClick={() => coreNavigate('/reports/')}>
                                <p>See all reports</p>
                                <IconControl iconKey={'arrowright'} />
                            </div>
                        </div>
                    }
                </div >
            </>
        );
    } else coreNavigate('/error/notfound/')

};

export default Landing;