import React, { createContext, useState, useEffect, useContext } from 'react';
import { useCoreStateContext } from 'spark-core-dx/contexts';


export const ReportsDomainContext = createContext();

export function ReportsDomainContextProvider({ children }) {
    const coreState = useCoreStateContext();    
    useEffect(() => {
        return () => {
            coreState.clear()
        }
    }, [])

    return (
        <ReportsDomainContext.Provider value={{}} >
            {children}
        </ReportsDomainContext.Provider>

    );
}

export const useReportsDomainContext = () => useContext(ReportsDomainContext);