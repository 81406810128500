import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'spark-core-dx/components';
import { LandingDomainContextProvider } from './LandingDomainProvider';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const LandingDomain = () => {
    return (
        <LandingDomainContextProvider>
            <Layout>
                <Outlet />
            </Layout>
        </LandingDomainContextProvider>
    );
};

export default LandingDomain;