import React, { useState } from 'react'
import { FieldControl, ButtonControl } from 'spark-core-dx/components';
import { useWizardDomainContext } from '../WizardDomainProvider';

const WizardPage = ({ id, header, subtitle, children, tabs }) => {
  const { handleOnSave, currentStep, setCurrentStep } = useWizardDomainContext();

  return (
    <div className="wizard-page">
      <div className="wizard-title">
        <h2>{id ? id + "." : ""} {header}</h2>
        <p>{subtitle}</p>
      </div>
      {tabs}
      {children}
    </div>
  )
}

export default WizardPage