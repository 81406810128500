import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'spark-core-dx/components';
import { ConvokeDomainContextProvider } from './ConvokeDomainProvider';

const ConvokeDomain = () => {
    return (
        <ConvokeDomainContextProvider>
            <Layout>
                <Outlet />
            </Layout>
        </ConvokeDomainContextProvider>
    );
};

export default ConvokeDomain;