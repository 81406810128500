import React from 'react';
import { Route } from 'react-router-dom';
import { SparkApp } from 'spark-core-dx';
import { DomainRoute } from 'spark-core-dx/domains';
import Home from './domains/public/screens/Home';
import HomeDomain from './domains/home/HomeDomain';
import ReportsDomain from './domains/reports/ReportsDomain';
import Reports from './domains/reports/screens/Reports';
import LandingDomain from './domains/landing/LandingDomain';
import Landing from './domains/landing/screens/Landing';
import { useCoreRoute } from 'spark-core-dx/hooks';
import PublicDomain from './domains/public/PublicDomain'
import WizardDomain from './domains/wizard/WizardDomain';
import ImplementationWizard from './domains/wizard/screens/ImplementationWizard';
import SearchSourceObject from './domains/convoke/screens/SearchSourceObject';
import ConvokeDomain from './domains/convoke/ConvokeDomain';
import EntityObjectFieldMapping from './domains/convoke/screens/EntityObjectFieldMapping';

const defaultLoadingScreen = () => {
  return <svg width="634" height="248" viewBox="0 0 634 248" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1007_26456)">
      <path style={{ "--endingColor": "#F58113" }} d="M515.988 79.8203C493.976 79.8203 476.071 61.9163 476.071 39.8816C476.071 17.887 493.976 0.00164795 515.988 0.00164795C538.004 0.00164795 555.872 17.887 555.872 39.8816C555.872 61.9163 538.004 79.8203 515.988 79.8203Z"
        stroke="#F58113" strokeWidth="2" strokeDasharray={500} strokeDashoffset={500} />
      <path style={{ "--endingColor": "#1A67BB" }} d="M308.927 217.789C323.061 217.789 333.568 212.244 333.568 199.812C333.568 186.524 322.432 181.513 308.927 181.513H269.523V217.789H308.927ZM307.044 154.612C318.891 154.612 327.725 149.658 327.725 138.301C327.725 128.274 320.449 121.983 307.044 121.983H269.523V154.612H307.044ZM383.3 203.655C383.3 231.04 362.921 247.587 324.743 247.587H222.889V135.844C222.889 111.176 237.093 92.1949 268.028 92.1949H320.449C356.721 92.1949 374.087 106.798 374.087 131.699C374.087 148.308 363.541 159.123 349.073 164.372V165.443C359.325 166.758 383.3 177.577 383.3 203.655ZM103.092 124.512L77.1479 185.344H130.709L104.765 124.512H103.092ZM137.528 92.1949L211.529 247.587H156.357L142.432 214.33H65.4703L51.5303 247.587H0C0 247.587 36.8849 171.125 49.5464 143.201C64.362 110.366 77.0161 92.1949 107.826 92.1949H137.528ZM633.493 247.587H590.245L580.041 153.907H577.74L545.055 247.587H487.003L454.343 153.907H452.019L441.805 247.587H398.604C398.604 247.587 407.244 168.801 410.785 136.927C414.048 107.29 429.561 92.1949 456.891 92.1949H476.52L515.207 201.54H516.88L555.563 92.1949H574.989C602.196 92.1949 617.724 107.298 621.135 136.96C624.644 167.627 633.493 247.587 633.493 247.587Z"
        stroke="#1A67BB" strokeWidth="2" strokeDasharray={1800} strokeDashoffset={1800} />
    </g>
  </svg>
}

const App = () => {

  const { getRoute, currentDomain } = useCoreRoute()

  // Reserved /login /logout /admin/* 
  return (
    <SparkApp
      asyncRoutes={[
        '/',
      ]}
      addonRoutes={[
        <Route key={"Home"} element={<DomainRoute component={PublicDomain} />} > {/* Domain */}
          <Route  exact path={'/'} element={<Home />} /> {/* Screens */}
        </Route>,
        <Route key={"Reports"} element={<DomainRoute component={ReportsDomain} domainName={'Reports'}  />} >
          <Route exact path={'/Reports'} element={<Reports />} /> {/* Screens */}
        </Route>,
        <Route key={"Convoke"} element={<DomainRoute component={ConvokeDomain} domainName={'Convoke'} />} >
          <Route path={getRoute("/convoke/sosearch/","Convoke") + "/:soId"} element={<SearchSourceObject />} /> {/* Screens */}
        </Route>,
        <Route key={"Convoke"} element={<DomainRoute component={ConvokeDomain} domainName={'Convoke'} />} >
        <Route path={getRoute("/convoke/eofieldmapping/","Convoke") + "/:eoId"} element={<EntityObjectFieldMapping />} /> {/* Screens */}
      </Route>,
        <Route key={"AuthorizedHome"} element={<DomainRoute key={'AuthorizedHome'} domainName={'AuthorizedHome'} component={LandingDomain} />} >
          <Route path={getRoute('/landing/', "AuthorizedHome")} element={<Landing />} />
        </Route>,
        <Route key="Wizard" element={<DomainRoute key={'OnboardingWizard'} domainName={'OnboardingWizard'} component={WizardDomain} />} >
          <Route path={getRoute('/onboardingwizard/start/', "OnboardingWizard")} element={<ImplementationWizard />} />
          <Route path={getRoute('/onboardingwizard/start/', "OnboardingWizard") + '/:cid'} element={<ImplementationWizard />} />
        </Route>
      ]} />
  )
}

export default App;
