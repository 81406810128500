import React, { useEffect, useRef } from 'react';

const Confetti = ({ containerRef }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const particles = [];
        const colors = ['#ff5e57', '#ffbb00', '#28c7fa', '#58d68d', '#a569bd'];
        let animationFrameId;

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const getContainerPosition = () => {
            const rect = containerRef.current.getBoundingClientRect();
            return {
                x: rect.left + rect.width / 2,
                y: rect.top + rect.height / 2,
            };
        };

        const createParticles = () => {
            const { x, y } = getContainerPosition();
            for (let i = 0; i < 150; i++) {
                particles.push({
                    x,
                    y,
                    size: Math.random() * 5 + 5,
                    color: colors[Math.floor(Math.random() * colors.length)],
                    velocityX: (Math.random() - 0.5) * 8, // Horizontal spread
                    velocityY: Math.random() * -12 - 5,   // Initial upward speed
                    gravity: 0.1,                         // Gravity for downward pull
                    rotation: Math.random() * 360,
                    rotationSpeed: Math.random() * 5 - 2.5,
                });
            }
        };

        const animateParticles = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            particles.forEach((particle) => {
                particle.velocityY += particle.gravity;

                particle.x += particle.velocityX;
                particle.y += particle.velocityY;

                particle.rotation += particle.rotationSpeed;

                ctx.save();
                ctx.translate(particle.x, particle.y);
                ctx.rotate((particle.rotation * Math.PI) / 180);
                ctx.fillStyle = particle.color;
                ctx.fillRect(-particle.size / 2, -particle.size / 2, particle.size, particle.size);
                ctx.restore();
            });

            if (particles.some(p => p.y < canvas.height)) {
                animationFrameId = requestAnimationFrame(animateParticles);
            }
        };

        createParticles();
        animateParticles();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [containerRef]);

    return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', zIndex: 0 }} />;
};

export default Confetti;
