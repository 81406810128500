import React, { useState, useRef } from 'react'
import './success.scss'
import { useNavigate } from 'react-router-dom';
import { ButtonControl, DialogControl } from 'spark-core-dx/components';
import { useGenericManager } from 'spark-core-dx/hooks';
import WizardFooter from './components/WizardFooter';
import { useWizardDomainContext } from '../WizardDomainProvider';
import Confetti from '../../../components/Confetti';
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';


const Success = () => {
    const { updateOnboardingProcess, onboardingItem, currentStep, setCurrentStep, clearFranchiseeState } = useWizardDomainContext();
    const itemService = new ItemServiceFactory()
    const emailReqMgr = useGenericManager(itemService.ItemServiceEnum.EmailRequest, itemService);
    const [reviewClicked, setReviewClicked] = useState(onboardingItem.Status >= 3)
    const navigate = useNavigate();
    const [reviewConfirmed, setReviewConfirmed] = useState(onboardingItem.Status === 4)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [confetti, setConfetti] = useState(false)

    const containerRef = useRef(null)

    const handleExit = () => {
        navigate(`/wizard/`)
        clearFranchiseeState()
    }

    const handleConfetti = () => {
        setConfetti(false);
        setTimeout(() => setConfetti(true), 0);
    };

    const handleReviewClick = async () => {
        setReviewClicked(true)
        const result = await emailReqMgr.RunOperation({
            operationName: "SendOnboardingEmails",
            queryParams: {
                cid: onboardingItem.ClientId,
                emailType: "2"
            },
            isWarning: true
        })
        await updateOnboardingProcess(9) // InReview Status Set.
    }

    const handleReviewCompletedClick = () => {
        setDialogOpen(true)
    }

    const handleDialogOkClick = async () => {
        await updateOnboardingProcess(10);
        setDialogOpen(false)
        setReviewConfirmed(true)
    }

    const handleGoLiveClick = async () => {
        const result = await emailReqMgr.RunOperation({
            operationName: "SendOnboardingEmails",
            queryParams: {
                cid: onboardingItem.ClientId,
                emailType: "3"
            },
            isWarning: true
        })
        if (result.Success) {
            await updateOnboardingProcess(11);
            handleConfetti();
        }

    }

    return (
        <>
            <DialogControl
                openDialog={dialogOpen}
                title={`Confirm Review Completion`}
                onCancel={() => setDialogOpen(false)}
                onOk={handleDialogOkClick}
                okText={'Confirm'}>
                <div className="dialog-text">
                    Please click "Confirm" to confirm the completion of the review.

                </div>
            </DialogControl>

            <div className='wizard-content'>
                <div className="success-content">
                    <div className="success-container" ref={containerRef}>
                        {confetti && <Confetti containerRef={containerRef} />}
                        <div className="checkmark-container">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <h1>Success!</h1>
                    </div>
                    <div className="text-container">
                        <div className={`text-section${!reviewConfirmed ? '' : ' disabled'}`}>
                            <p>
                                {
                                    !reviewClicked ?
                                        'Clicking the "Review" button will send out emails to the validation team to begin the review and validation process.' :
                                        'Click the "Review Completed" button to confirm the completion of the review.'

                                }
                            </p>
                            <div className="review-button">
                                <ButtonControl minWidth={'8rem'} value={!reviewClicked ? 'Review' : 'Review Completed'} type={'okay'} onClick={!reviewClicked ? handleReviewClick : handleReviewCompletedClick} />
                            </div>
                        </div>
                        <div className={`text-section${reviewConfirmed ? '' : ' disabled'}`}>
                            <p>Clicking the "Go Live" button below will send out emails too all users within the franchisee to get started on the platform.</p>
                            <div>
                                <ButtonControl minWidth={'8rem'} value={'Go Live'} type={'cancel'} onClick={handleGoLiveClick} />
                            </div>
                        </div>
                        <p>If you're not ready yet, you can exit using the "Exit" button.</p>
                    </div>
                </div>
                <svg className={`squiggly-arrow`} width="336" height="169" viewBox="0 0 336 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.87675 2C0.75416 20.2168 -3.95412 44.7886 17.0345 59.32C34.1184 71.148 60.57 72.1713 81.9995 68.43C94.1497 66.3088 112.21 60.4218 112.925 49.2479C113.284 43.6218 112.382 26.1788 100.021 27.5761C91.5302 28.5359 85.5642 34.6829 80.2938 39.2891C70.6476 47.7196 64.4927 56.7161 63.9042 68.0905C62.3879 97.4007 119.192 103.713 147.113 99.4949C175.662 95.1814 205.751 78.4788 215.193 57C219.038 48.2523 227.345 19.8187 209.853 15.6934C185.826 10.027 162.863 36.9099 156.086 50.3796C147.555 67.3385 148.125 92.6384 174.701 100.344C194.218 106.002 212.254 93.6087 226.613 85.4619C236.836 79.6623 259.879 65.3725 274.596 70.2973C317.662 84.7092 310.564 119.403 310.564 148.157C310.564 153.7 306.172 161.974 310.564 167" stroke="black" strokeWidth="3" strokeLinecap="round" />
                    <path d="M284 135.837C285.373 137.573 309.187 170.246 311.619 166.232C319.655 152.973 327.013 137.928 333.977 124" stroke="black" strokeWidth="3" strokeLinecap="round" />
                </svg>
            </div>
            <WizardFooter
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentStep(currentStep - 1)}
                showSecBtn={false}
                primaryBtnOnClick={() => handleExit()}
                primaryBtnText={"Exit"}
                secBtnText={"Save"} />
        </>
    )
}

export default Success