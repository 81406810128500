import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'spark-core-dx/components';
import { WizardDomainContextProvider } from './WizardDomainProvider';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const WizardDomain = () => {
    return (
        <WizardDomainContextProvider>
            <Layout>
                <Outlet />
            </Layout>
        </WizardDomainContextProvider>
    );
};

export default WizardDomain;