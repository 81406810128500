import React, { useState, useEffect } from 'react'
import { FieldControl, ButtonControl } from 'spark-core-dx/components';
import { SystemTypes } from 'spark-core-dx/services';
import './wizardstart.scss'
import { useNavigate } from 'react-router-dom';
import { useWizardDomainContext } from '../WizardDomainProvider';

const WizardStart = () => {
    const { onboardingActiveList, setCurrentStep, setLoading } = useWizardDomainContext();
    const [franchiseeId, setFranchiseeId] = useState()
    const navigate = useNavigate();
    const [buttonValue, setButtonValue] = useState("Start")

    useEffect(() => {
        if (franchiseeId) {
            setButtonValue("Continue")
        } else {
            setButtonValue("Start")
        }
    }, [franchiseeId])

    const handleButtonClick = () => {
        if (buttonValue === "Continue") {
            navigate(`/wizard/${franchiseeId}`)
            setLoading(true)
        } else {
            setCurrentStep(1)
        }
    }

    return (
        <div className="wizard-start">
            <div className="wizard-center-text">
                <h1>Configure a new ABMFG Franchisee Account</h1>
                <p>Welcome to the ABMFG Onboarding Wizard!</p>
                <p>If you are onboarding a new franchisee, please click the "Start" button to begin.</p>
                <p>If you are resuming the onboarding process for an existing franchisee, please select your franchisee from the list below and click "Continue."</p>
            </div>
            <ButtonControl className={'form-footer-btn'} minWidth={'9rem'} value={buttonValue} type={'okay'} onClick={handleButtonClick} />

            <div className={`wizard-continue-select ${onboardingActiveList && onboardingActiveList.length > 0 ? '' : ' disabled'}`}>
                <h5>Select a franchisee below to resume the onboarding process</h5>
                <FieldControl
                    key={"logo"}
                    fieldMetadata={{
                        FieldType: SystemTypes.UIFieldType.SearchSelectList,
                        FieldName: "FranchiseeId",
                    }}
                    selectData={onboardingActiveList}
                    value={franchiseeId}
                    onChange={setFranchiseeId}
                />
            </div>
        </div>
    )
}

export default WizardStart