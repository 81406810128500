import React, { useState, useEffect } from 'react'
import WizardFooter from './WizardFooter'
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { Loading } from 'spark-core-dx/components'

const IntakeSage = ({ setCurrentTab, franchiseeOptions }) => {
  const { currentStep, setCurrentStep, updateOnboardingProcess, handleProgress } = useWizardDomainContext();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (franchiseeOptions.length > 0) {
      setIsLoading(false)
    }
  }, [franchiseeOptions])

  const handleOnSave = async (isContinue) => {
    if (isContinue) {
      setCurrentStep(currentStep + 1)
      await handleProgress(1, 1, 'page52', updatedPageValues => {
        updateOnboardingProcess(currentStep + 1, updatedPageValues);
      });
    } else
      await handleProgress(1, 1, 'page52', updatedPageValues => {
        updateOnboardingProcess(currentStep, updatedPageValues);
      });
  }

  return (
    <>
      {
        !isLoading ?
          <div className="wizard-content">
            Coming soon..
          </div>
          :
          <div className='wizard-content'>
            <Loading type={'spinner-2'} />
          </div>
      }

      <WizardFooter
        disableAll={isLoading}
        showExportBtn={true}
        exportBtnOnClick={{}}
        prevBtnOnClick={() => setCurrentTab(1)}
        showPrevBtn={true}
        primaryBtnOnClick={async () => await handleOnSave(true)}
        primaryBtnText={"Save & Continue"}
        showSecBtn={true}
        secBtnOnClick={async () => await handleOnSave()}
        secBtnText={"Save"} />
    </>
  )
}

export default IntakeSage