import React, { useState } from 'react';
import Accordion from '../components/Accordion';
import { FieldControl, Loading } from 'spark-core-dx/components';
// $"clientId=hello;url=url;clientSecret=clientsecret;tenantId=tenantId;version=version";

const BuildOpsSetup = ({ data, appName, errorApps, updateData, }) => {

    return (
        <Accordion
            key={appName}
            title={appName}
            opened={errorApps.includes(appName.toLowerCase())}
            btnText={'Export'}
            btnOnClick={{}}
        >
            <FieldControl
                key={'tenantId'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `tenantId`,
                    DisplayName: 'Tenant ID',
                    IsRequired: true,
                }}
                value={data?.tenantId}
                onChange={updateData}
            />
            <FieldControl
                key={'clientId'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `clientId`,
                    DisplayName: 'Client ID',
                    IsRequired: true,
                }}
                value={data?.clientId}
                onChange={updateData}
            />
            <FieldControl
                key={'secret'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 3,
                    FieldName: `clientSecret`,
                    DisplayName: 'Client Secret',
                    IsRequired: true,
                }}
                value={data?.clientSecret}
                onChange={updateData}
            />
            <FieldControl
                key={'url'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `url`,
                    DisplayName: 'URL',
                    IsRequired: true,
                    DefaultSelection: "https://public-api.live.buildops.com/"
                }}
                value={data?.url}
                onChange={updateData}
            />
            <FieldControl
                key={'version'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `version`,
                    DisplayName: 'Version',
                    IsRequired: true,
                    DefaultSelection: "v1"
                }}
                value={data?.version}
                onChange={updateData}
            />
        </Accordion>
    );
};

export default BuildOpsSetup;