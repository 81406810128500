import React, { useState, useEffect } from 'react'
import WizardFooter from './WizardFooter'
import { useWizardDomainContext } from '../../WizardDomainProvider'
import { Loading } from 'spark-core-dx/components'

const IntakeBO = ({ setCurrentTab, franchiseeOptions }) => {
  const { currentStep, setCurrentStep, handleProgress, updateOnboardingProcess } = useWizardDomainContext();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (franchiseeOptions.length > 0) {
      setIsLoading(false)
    }
  }, [franchiseeOptions])

  const handleOnSave = async (isContinue) => {
    if (isContinue) {
      setCurrentTab(2)
    }
    await handleProgress(1, 1, 'page51', updatedPageValues => {
      updateOnboardingProcess(currentStep, updatedPageValues);
    });
  }

  return (
    <>
      {
        !isLoading ?
          <div className="wizard-content">
            In our current implementation with BuildOps, no further information is needed. Please continue to the next tab.
          </div>
          :
          <div className='wizard-content'>
            <Loading type={'spinner-2'} />
          </div>
      }
      <WizardFooter
        disableAll={isLoading}
        // showExportBtn={true}
        exportBtnOnClick={{}}
        prevBtnOnClick={() => setCurrentStep(currentStep - 1)}
        showPrevBtn={true}
        primaryBtnOnClick={async () => await handleOnSave(true)}
        primaryBtnText={"Continue"}
      // showSecBtn={true}
      // secBtnOnClick={async () => await handleOnSave()}
      // secBtnText={"Save"} 
      />
    </>
  )
}

export default IntakeBO