import React from 'react'
import WizardStart from './WizardStart'
import Wizard from './Wizard'
import { useWizardDomainContext } from '../WizardDomainProvider';
import { Loading } from 'spark-core-dx/components';

const ImplementationWizard = () => {
  // Need to make the paging system.
  const { currentStep, loading } = useWizardDomainContext();

  const handleStep = (stepNumber) => {
    stepNumber = Number(stepNumber)
    switch (stepNumber) {
      case 0:
        return <WizardStart />
      default:
        return <Wizard />
    }
  };

  return (
    <>
      {loading ? <Loading type={'spinner-2'} /> : handleStep(currentStep)}
    </>
  )
}

export default ImplementationWizard