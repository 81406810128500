import React, { useEffect,useRef } from 'react'
import '../Reports.scss'
import { AuthManager } from 'spark-core-dx/managers'
import { useCoreContext} from 'spark-core-dx/contexts'

export const ReportingIFrame = ({ reportId }) => {
    const { Launch, ReportViewerUrl,Profile } = useCoreContext(); //Launch is comming soon
    const cssStyles = `
        html{
            background-color:#f5f5f5;
        }
        body{
            font-size:14px;
            background-color:white;
            height: 100%;
            margin:unset;
        }

        .message-wrapper {
            width:100%;
            height:100%;
            color: red;
            font-weight: bold;
            font-size:1rem;
        }
    `;

    const iframeRef = useRef(null);
    useEffect(() => {
        if(Profile && iframeRef.current)
        {
            const access = AuthManager.getTokens().Access;
                iframeRef.current.onload = () => {
                    iframeRef.current.contentWindow.postMessage({ 
                        token: access,
                        cId:`${Profile.ClientId}`,
                        appId:`${Profile.AppId}`,
                        css: cssStyles}, 
                        ReportViewerUrl ?? `https://localhost:44344`);
                }; 
        }
    }, [Profile]);

    if(!reportId){
        reportId = '9b5bc7f3-9194-41a9-819f-10f87fed05d2';
    }
    return (
        <iframe
            ref={iframeRef}
            src={`${(ReportViewerUrl ?? `https://localhost:44344`)}?id=${reportId}`}
            title="Reporting"
            style={{ width: '100%', height: '100%' }}
        ></iframe>
    );
};