import React, { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom';
import './searchSourceObject.scss'
import { useConvokeDomainContext } from '../ConvokeDomainProvider';
import {TableControl} from 'spark-core-dx/components';
import {useCoreRoute} from 'spark-core-dx/hooks'
const SearchSourceObject = ({ }) => {

    const [data, setData] = useState(null)
    const {coreNavigate} = useCoreRoute();
    const { genericMgr, loadingState } = useConvokeDomainContext();
    const {soId} = useParams();

    useEffect(() => {
        if (!data) {
            genericMgr?.search({id:soId})
        }
    }, [])

    useEffect(() => {
        
        if (!loadingState && genericMgr) {
            
            setData(genericMgr?.filteredData)
        }
    }, [loadingState, genericMgr?.filteredData])


    const onServerSideQuery = async () => {
        const searchModel = genericMgr?.State['serverSideQuery']
        await genericMgr.search({ searchModel: searchModel.trim() === 'x =>' ? null : genericMgr?.State['serverSideQuery'], forceUpdate: true })
    }

    return ( <>
        <div className='sourceObjectSearch back-link' onClick={()=>{coreNavigate("/dynamic/sourceobject/")}}>{"<<"} Back to Source Object List</div>
        <TableControl
            data={data}
            enableMultiSelect={false}
            defaultNumRows={50}
            onServerSideQuery={onServerSideQuery}
            metadata={genericMgr?.uiMetadata}
            loadingState={loadingState}
            filters={genericMgr?.filters}
            mgr={genericMgr}
            enableSidePanelEdit={true}
        />
        </>
    );
}

export default SearchSourceObject;



