import React, { useEffect, useState } from 'react'
import { useGenericManager } from 'spark-core-dx/hooks'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory'
import { useParams } from 'react-router-dom';
import { useWizardDomainContext } from '../WizardDomainProvider';
import IntakeBO from './components/IntakeBO';
import IntakeSage from './components/IntakeSage';

const Intake = () => {
    const { currentStep, setCurrentStep, updateOnboardingProcess } = useWizardDomainContext();
    const { cid } = useParams();

    const itemService = new ItemServiceFactory()
    const externalAppMgr = useGenericManager(itemService.ItemServiceEnum.ExternalApp, itemService);
    const franchiseeExternalAppSettingMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeExternalAppSetting, itemService);

    const [franchiseeOptions, setFranchiseeOptions] = useState([])
    const [francEASItems, setFrancEASItems] = useState([])

    const [applicationList, setApplicationList] = useState({})

    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        grabData();
    }, [])

    const grabData = async () => {
        externalAppMgr.search({}).then(r => {
            setFranchiseeOptions(r)
        })
        franchiseeExternalAppSettingMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(r => {
            setFrancEASItems(r)
        })
    }

    useEffect(() => {
        const mapApplicationsByCategory = () => {
            const categoryMap = {};

            francEASItems.forEach((object) => {
                const appList = franchiseeOptions.find(app => app.UniqueId === object.ExternalAppId);
                if (appList && appList.CategoryName) {
                    if (!categoryMap[appList.CategoryName]) {
                        categoryMap[appList.CategoryName] = [];
                    }
                    categoryMap[appList.CategoryName].push(appList.Name);
                }
            });

            return categoryMap;
        }
        const appList = mapApplicationsByCategory()
        setApplicationList(appList)
        setCurrentTab(1)
    }, [francEASItems, franchiseeOptions])

    const renderSteps = () => {
        switch (currentTab) {
            case 1: {
                return <IntakeBO franchiseeOptions={franchiseeOptions} setCurrentTab={setCurrentTab} />
            }
            case 2: {
                return <IntakeSage franchiseeOptions={franchiseeOptions} setCurrentTab={setCurrentTab} />;
            }
        }
    }

    return (
        <>
            <div className="wizard-tabs">
                {["BuildOps", "Sage"].map((tab, index) => {
                    return (
                        <div key={index}
                            className={`wizard-tab` + ((currentTab === index + 1) ? ' active-tab' : '')}
                            onClick={() => setCurrentTab(index + 1)}>
                            {tab}
                        </div>
                    )
                })}
            </div>
            {renderSteps()}
        </>
    )
}

export default Intake