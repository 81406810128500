import React, { createContext, useState, useEffect, useContext } from 'react';
import {useLocation} from 'react-router-dom';
import { useCoreStateContext,useCoreContext } from 'spark-core-dx/contexts';
import { useCoreRoute } from 'spark-core-dx/hooks';
import ConvokeSourceObjectManager from '../../managers/convoke/ConvokeSourceObjectManager';
import { ItemServiceFactory } from '../../services/ItemServiceFactory';

export const ConvokeDomainContext = createContext();

export function ConvokeDomainContextProvider({ children }) {
    const coreState = useCoreStateContext();
    const coreContext = useCoreContext();
    const location = useLocation();
    const { currentRoute } = useCoreRoute();
    const [loadingState, setLoadingState] = useState(true)
    const genericMgr = new ConvokeSourceObjectManager(coreContext, coreState, (new ItemServiceFactory()).ItemServiceEnum.SourceObject,true);

    useEffect(() => {
        return () => {
            coreState.clear()
        }
    }, [])

    useEffect(() => {
        const routeKey = currentRoute?.ItemName + "_data";
        if (!coreState.get(routeKey) || coreState.get(routeKey).loadingMetadata || coreState.get(routeKey).loadingSearch) {
            setLoadingState(true)
        } else if (!genericMgr.loading.All) {
            setLoadingState(false)
        }
    }, [coreState, location])


    return (
        <ConvokeDomainContext.Provider value={{ genericMgr, loadingState: coreState.global.get('isNavigating') ? true : loadingState }}>
            {children}
        </ConvokeDomainContext.Provider>
    );
}
export const useConvokeDomainContext = () => useContext(ConvokeDomainContext);