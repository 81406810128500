import React from 'react'
import './reportCard.scss'

const ReportCard = ( {disabled, src, reportTitle, reportDate, reportDescription} ) => {
  return (
    <div className={`report-card${disabled ? " disabled" : ""}`}>
        <div className="img-container">
            <img src={src} draggable="false" />
        </div>
        <div className='report-text'>
          <div className='report-header'>
            <h4>{reportTitle}</h4>
            <p>{reportDate}</p>
          </div>
            <p>{reportDescription}</p>
        </div>
    </div>
  )
}

export default ReportCard