import React, { useState, useEffect, useMemo } from 'react'
import TreeNode from './TreeNode.jsx'

export const findNodeById = (nodes, id) => {
  for (let node of nodes) {
    if (node.id === id) return node;
    if (node.child) {
      const childNode = findNodeById(node.child, id);
      if (childNode) return childNode;
    }
  }
  return null;
};

const MultiSelectTreeControl = ({
  expanded,
  selectedNodes,
  setSelectedNodes,
  arrayOfObj,
  data,
  setText,
  searchQuery,
  disabledList,
  onChange }) => {
  const [expandedNodes, setExpandedNodes] = useState(expanded || []);

  const convertArrOfObjToJSON = (dataArray) => {
    const result = [];
    let idCounter = 1;

    const categoryGroups = dataArray.reduce((acc, item) => {
      const { CategoryName } = item;
      if (!acc[CategoryName]) {
        acc[CategoryName] = [];
      }
      acc[CategoryName].push({ ...item, id: idCounter++ });
      return acc;
    }, {});

    Object.keys(categoryGroups).forEach((category) => {
      const categoryId = idCounter++;

      const children = categoryGroups[category].map((child) => ({
        id: child.id,
        name: child.Name,
        disabled: (disabledList?.includes(child.Name)) || child.Disabled,
        parent_id: categoryId,
      }));

      result.push({
        id: categoryId,
        name: category,
        disabled: children.every(child => child.disabled),
        child: children.length > 0 ? children : undefined,
      });
    });

    return result;
  };

  const convertedData = useMemo(() => {
    if (!data && arrayOfObj) {
      return convertArrOfObjToJSON(arrayOfObj);
    }
    return data;
  }, [data, arrayOfObj, disabledList]);

  useEffect(() => {
    if (searchQuery) {
      const findNodesToExpand = (nodes, query) => {
        const result = new Set();

        const searchNodes = (nodes) => {
          for (let node of nodes) {
            if (node.name.toLowerCase().includes(query.toLowerCase())) {
              result.add(node.name);
              if (node.parent_id) {
                const addParentNodes = (id) => {
                  const parent = findNodeById(convertedData, id);
                  if (parent && !result.has(parent.name)) {
                    result.add(parent.name);
                    if (parent.parent_id) {
                      addParentNodes(parent.parent_id);
                    }
                  }
                };
                addParentNodes(node.parent_id);
              }
            }
            if (node.child) {
              searchNodes(node.child);
            }
          }
        };

        searchNodes(nodes);
        return Array.from(result);
      };
      setExpandedNodes(findNodesToExpand(convertedData, searchQuery));
    } else {
      if (!expanded) {
        setExpandedNodes([]);
      }
    }
  }, [searchQuery]);

  const handleExpand = (node) => {
    setExpandedNodes(prev => {
      if (prev.includes(node.name)) {
        return prev.filter(name => name !== node.name);
      } else {
        return [...prev, node.name];
      }
    });
  };

  const handleSelect = (node) => {
    let newSelectedNodeNames = [...selectedNodes];

    const selectAllChildren = (node) => {
      if (node.child) {
        node.child.forEach((childNode) => selectAllChildren(childNode));
      } else if (!node.disabled && !newSelectedNodeNames.includes(node.name)) {
        newSelectedNodeNames.push(node.name);
      }
    };

    const deselectAllChildren = (node) => {
      if (node.child) {
        node.child.forEach((childNode) => deselectAllChildren(childNode));
      } else if (!node.disabled) {
        newSelectedNodeNames = newSelectedNodeNames.filter(name => name !== node.name);
      }
    };

    const isAnyChildSelected = (node) => {
      if (!node.child) return false;
      return node.child.some(childNode => newSelectedNodeNames.includes(childNode.name) || isAnyChildSelected(childNode));
    };

    const areAllChildrenSelected = (node) => {
      if (!node.child) return true;
      return node.child.every(childNode => {
        return (childNode.disabled || newSelectedNodeNames.includes(childNode.name) && areAllChildrenSelected(childNode));
      });
    };

    if (node.child) {
      if (areAllChildrenSelected(node)) {
        deselectAllChildren(node);
      } else {
        selectAllChildren(node);
      }
    } else {
      if (newSelectedNodeNames.includes(node.name)) {
        newSelectedNodeNames = newSelectedNodeNames.filter(name => name !== node.name);
      } else {
        newSelectedNodeNames.push(node.name);
      }
    }

    const deselectParents = (node) => {
      if (node.parent_id) {
        const parent = findNodeById(convertedData, node.parent_id);
        if (parent && !isAnyChildSelected(parent) && !parent.disabled) {
          deselectParents(parent);
        }
      }
    };

    deselectParents(node);

    setSelectedNodes(newSelectedNodeNames);
    onChange && onChange({ target: { value: newSelectedNodeNames } });

    if (setText) {
      setText(newSelectedNodeNames.join(', '), newSelectedNodeNames.length);
    }
  };

  return (
    <div>
      {convertedData?.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          selectedNodes={selectedNodes}
          handleSelect={handleSelect}
          expandedNodes={expandedNodes}
          handleExpand={handleExpand}
        />
      ))}
    </div>
  );
};

export default MultiSelectTreeControl