import React, { useState, useEffect, useRef } from 'react'
import './wizard.scss'
import { useCoreRoute } from 'spark-core-dx/hooks';
import { useWizardDomainContext } from '../WizardDomainProvider';
import { IconControl, FieldControl, ButtonControl, InfoControl, DialogControl } from 'spark-core-dx/components';
import { SystemTypes } from 'spark-core-dx/services';
import { useParams, useNavigate } from 'react-router-dom';
import WizardPage from './WizardPage';
import CompanyInformation from './components/CompanyInformation';
import Locations from './components/Locations';
import FranchiseeOptions from './FranchiseeOptions';
import Success from './Success';
import FranchiseeSetup from './FranchiseeSetup';
import SystemApplicationSetup from './SystemApplicationSetup';
import Intake from './Intake';
import SetupUsers from './SetupUsers';

const Wizard = () => {
  const { pageValues, currentStep, setCurrentStep, onboardingItem, onboardingActiveList, clearFranchiseeState } = useWizardDomainContext();
  const { currentRoute } = useCoreRoute();
  const navigate = useNavigate();
  const [franchiseeId, setFranchiseeId] = useState(null)
  const { cid } = useParams();
  const [openInfoMenu, setOpenInfoMenu] = useState(false)
  const infoIconRef = useRef(null)
  const [onboardingList, setOnboardingList] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const [progressList, setProgressList] = useState([{
    id: 1,
    title: "Franchisee Setup",
    subtitle: "Setup your Franchisee Account.",
    enabled: true,
    value: pageValues.page1,
    sub: [{
      id: 1,
      title: 'Company Information',
      value: pageValues.page11,
    }, {
      id: 2,
      title: 'Logos',
      value: pageValues.page12,
    }, {
      id: 3,
      title: 'Branding',
      value: pageValues.page13,
    },
    {
      id: 4,
      title: 'System Configuration',
      value: pageValues.page14,
    }]
  },
  {
    id: 2,
    title: "Company Information",
    subtitle: "Enter your company's information here.",
    enabled: false,
    value: pageValues.page2,
  },
  {
    id: 3,
    title: "Location(s)",
    subtitle: "Enter your company’s location(s) below.",
    enabled: false,
    value: pageValues.page3,
  },
  {
    id: 4,
    title: "Franchisee Options",
    subtitle: "Select franchisee configuration options.",
    enabled: false,
    value: pageValues.page4,
  },
  {
    id: 5,
    title: "Intake",
    subtitle: "Intake forms for the selected vendors.",
    enabled: false,
    value: pageValues.page5,
    sub: [{
      id: 1,
      title: 'BuildOps',
      value: pageValues.page51,
    }, {
      id: 2,
      title: 'Sage',
      value: pageValues.page52,
    }]
  },
  {
    id: 6,
    title: "Setup Users",
    subtitle: "Setup and verify users.",
    enabled: false,
    value: pageValues.page6,
  },
  {
    id: 7,
    title: "System & Application Setup",
    subtitle: "Create connections to selected applications with the platform.",
    enabled: false,
    value: pageValues.page7,
    sub: [{
      id: 1,
      title: 'System of Records',
      value: pageValues.page71,
    }, {
      id: 2,
      title: 'Application',
      value: pageValues.page72,
    }, {
      id: 3,
      title: 'Warehouse and Reporting',
      value: pageValues.page73,
    },
    {
      id: 4,
      title: 'Legacy Systems',
      value: pageValues.page74,
    }]
  },
  {
    id: 8,
    title: "Go Live",
    enabled: false,
  }
  ])

  // const pageComponents = {
  //   "1": <FranchiseeSetup />,
  //   // "12": <Logos />,
  //   // "13": <Branding />,
  //   // "14": <SystemConfiguration />,
  //   "2": <CompanyInformation />,
  //   "3": <Locations />,
  //   "4": <FranchiseeOptions />,
  //   "51": <IntakeBO />,
  //   "52": <IntakeSage />,
  //   "8": <Success />
  // };

  useEffect(() => {
    const removeActiveClient = onboardingActiveList?.filter((c) => c.ClientId !== cid)
    const addingNewOptionToOnboardingList = [{ Text: "+ Add New Franchisee", Value: "" }, ...removeActiveClient,]
    setOnboardingList(addingNewOptionToOnboardingList)
  }, [onboardingActiveList, cid])

  useEffect(() => {
    const updatedList = progressList.map((line) => {
      if (line.sub) {
        const updatedSub = line.sub.map((subItem) => {
          const subKey = `page${line.id}${subItem.id}`;
          return { ...subItem, value: pageValues[subKey] };
        });
        return { ...line, value: pageValues[`page${line.id}`], sub: updatedSub };
      } else {
        return { ...line, value: pageValues[`page${line.id}`] };
      }
    });
    setProgressList(updatedList);
  }, [pageValues]);

  useEffect(() => {
    setProgressList(prevList =>
      prevList.map(step => {
        return { ...step, enabled: step.id <= parseInt(onboardingItem.Step) }
      })
    )
  }, [currentStep, onboardingItem.Step])

  const handleCidChange = () => {
    navigate(`/wizard/${franchiseeId}`)
    clearFranchiseeState()
  }

  const renderWizardStep = () => {

    switch (currentStep) {
      case 1: {
        return <FranchiseeSetup />
      }
      case 2: {
        return <CompanyInformation />
      }
      case 3: {
        return <Locations />
      }
      case 4: {
        return <FranchiseeOptions />
      }
      case 5: {
        return <Intake />
      }
      case 6: {
        return <SetupUsers />
      }
      case 7: {
        return <SystemApplicationSetup tabs={progressList.find(x => x.id === 7).sub.map(y => y.title)} />
      }
      case 8: {
        return <Success />
      }
    }
  }

  return (
    <>
      <DialogControl
        openDialog={dialogOpen}
        title={`Redirect Confirmation`}
        onCancel={() => setDialogOpen(false)}
        cancelText={'Back'}
        onOk={() => handleCidChange()}
        okText={'Continue'}>
        <div className="dialog-text">
          <p>Please make sure to save all information before hitting continue.</p>
          <p>If information has not been saved, go back and select save.</p>
        </div>
      </DialogControl>
      <div className="table-top-row wizard">
        <div className="table-title-container">
          {currentRoute?.Icon && <IconControl width={'2rem'} height={'2rem'} iconKey={currentRoute?.Icon} />}
          <div className="table-title-wrapper">
            <h2 className="table-title">
              {currentRoute?.DisplayName}
            </h2>
            <div className={`info-icon-wrapper ` + (!currentRoute?.Description ? "hide-icon" : "")}
              ref={infoIconRef}
              onMouseOver={() => setOpenInfoMenu(currentRoute?.DisplayName)}
              onMouseLeave={() => setOpenInfoMenu(false)}>
              <IconControl iconKey={'info'} width={'1rem'} height={'1rem'} />
            </div>
            {/* <InfoControl
              openInfoMenu={openInfoMenu == currentRoute?.DisplayName}
              setOpenInfoMenu={setOpenInfoMenu}
              iconRef={infoIconRef}
              offsetTop={10}
              location={'bottom center'}
              maxWidth={20}
            >
              {currentRoute?.Description}
            </InfoControl> */}
          </div>
        </div>
        <div className='wizard-swap'>
          <div className={`wizard-continue-select ${onboardingList && onboardingList.length > 0 ? '' : ' disabled'}`}>
            <FieldControl
              key={"logo"}
              fieldMetadata={{
                FieldType: SystemTypes.UIFieldType.SearchSelectList,
                FieldName: "FranchiseeId",
                PlaceHolder: 'Add or switch to a different franchisee'
              }}
              selectData={onboardingList}
              value={franchiseeId}
              onChange={setFranchiseeId}
            />
          </div>
          <ButtonControl disabled={franchiseeId != null ? false : true} className={'form-footer-btn'} minWidth={'6rem'} value={'Continue'} type={'okay'} onClick={() => setDialogOpen(true)} />
        </div>
      </div>
      <div className="wizard-container">
        <div className="progress-container">
          {progressList.map((line, index) => {
            const isCurrentStep = line.id === currentStep

            if (line.id === progressList.length) {
              return (
                <div key={line.id} className={`progress-text ${isCurrentStep ? 'selected' : ''}`} onClick={() => line.enabled && setCurrentStep(line.id)}>
                  <div className={`progress-number ${onboardingItem?.Status === 5 ? 'complete' : line.enabled ? 'active' : 'inactive'}`}>
                    {onboardingItem?.Status === 5 ? <IconControl iconKey={"yes"} /> : line.id}
                  </div>
                  <div className={`progress-title ${line.enabled ? 'active' : 'inactive'}`}>
                    {line.title}
                  </div>
                </div>
              )
            } else {
              return (
                <>
                  <div key={line.id} className={`progress-text ${isCurrentStep ? 'selected' : ''}`} onClick={() => line.enabled && setCurrentStep(line.id)}>
                    <div className={`progress-number ${line.value === 100 ? 'complete' : line.enabled ? 'active' : 'inactive'}`}>
                      {line.value === 100 ? <IconControl iconKey={"yes"} /> : index + 1}
                    </div>
                    <div className={`progress-title ${line.enabled ? 'active' : 'inactive'}`}>
                      {line.title}
                    </div>
                  </div>
                  {
                    !isCurrentStep || !line.sub ?
                      <div className="progress-bar-container">
                        <div className="progress-bar-fill default-fill"></div>
                        <div className="progress-bar-fill" style={{ height: `${line.value}%` }}></div>
                      </div>
                      : ""
                  }

                  {isCurrentStep && line.sub && line.sub.length > 0 && (
                    <>
                      {line.sub.map((s, i) => {
                        return (
                          <div className="progress-sub" key={i}>
                            <div className="progress-bar-container">
                              <div className="progress-bar-fill default-fill"></div>
                              <div className="progress-bar-fill" style={{ height: `${s.value}%` }}></div>
                            </div>
                            <div className={`progress-title ${line.enabled ? 'active' : 'inactive'}`}>
                              {s.title}
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </>
              )
            }
          })}
        </div>
        <div className="wizard-page-container">
          {
            progressList
              .filter(line => line.id === currentStep)
              .map((step, stepIndex) => {
                return (
                  <WizardPage
                    key={step.id}
                    id={step.id}
                    header={step.title}
                    subtitle={step.subtitle}
                  >
                    {renderWizardStep()}
                  </WizardPage>
                );
              })
          }
        </div>
      </div>
    </>
  )
}

export default Wizard