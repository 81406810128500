import { ItemServiceFactory as DefaultItemServiceFactory } from 'spark-core-dx/services'
import { Manager } from 'spark-core-dx/managers'
import _ from 'lodash';
import { Operations } from '../../services/ItemServices';

class ConvokeSourceObjectManager extends Manager {
    constructor(coreContext, coreState, itemServiceName, isWarning) {
        super(coreContext, coreState, (coreContext.itemServiceFactory ?? DefaultItemServiceFactory).ItemServiceEnum[itemServiceName],"data");

        var factory = (coreContext.itemServiceFactory ?? DefaultItemServiceFactory);
        this._itemService = factory.GetItemService(itemServiceName, coreState, coreContext, isWarning);
        this._itemServiceFactory = coreContext.itemServiceFactory ?? DefaultItemServiceFactory;
        this.fieldData = {};
        this._coreContext = coreContext;
    }

    async Query({ model, params, skip, take, id }) {
            let soQueryFilter = model;
            take = take ?? 50000
            if (typeof (model) === 'string') {
                soQueryFilter = this.buildJSONObjFilter({ filter: model, params, skip, take });
            } else {
                soQueryFilter = { ...soQueryFilter, Take: take, Skip: skip }
            }
            return this.RunOperation({queryParams:{search:true}, operationName : Operations.SourceObjectItem.SearchSourceObject,id,item:soQueryFilter,isItem:true,isWarning:true}).then((r) => {
                return r;
            });
        }

    async GetMetadata({soId,forceReload}) {
   
            const foundUIMetadataInCache = this.cachedUIMetadata?.filter(x => x._id === soId);

            this.MergeState({
                loadingMetadata: true
            })
            if (this.uiMetadata && !forceReload) {

                this.MergeState({
                    uiMetadata: this.uiMetadata,
                    loadingMetadata: false
                })

                return {
                    Items: this.uiMetadata,
                    Cached: true,
                }
            } else if (foundUIMetadataInCache?.length > 0 && !forceReload) {
                //Added Cached as a field as true just to indicate that the return data is a cached data.
                this.MergeState({
                    uiMetadata: foundUIMetadataInCache,
                    loadingMetadata: false
                })
                return {
                    Items: foundUIMetadataInCache,
                    Cached: true,
                }
            } else {
                return this._itemService.single(soId).then(async (r) => {
                    if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                        let result = r.first();
                        for (const metadata of r.first().Items) {
                            let selectDataPromise = [];
                            metadata.Fields.forEach(x => {
                                if (x.DataJson || x.DataOperationItem || x.DataOperation)
                                    selectDataPromise.push(this.GetSelectData(x))
                            })
                        }

                        this.MergeState({
                            uiMetadata: result.Items,
                            loadingMetadata: false
                        })
                        this.MergeGlobalCachedMetadata({
                            cachedUIMetadata: this.cachedUIMetadata ? _.uniqBy([...this.cachedUIMetadata, ...r.first().Items], '_id') : r.first().Items,
                        })

                        return result;
                    }
                    else {
                        this.MergeState({
                            loadingMetadata: false
                        })
                        return r?.first();
                    }
                });
            }

    }

    //Model can be string query or the actual JSON object
    //Force updates forces a rerender.
    //Params are the field names in the string query. 
    //Skip 0 based index, defines starting position. 
    //Take Amount of records to return from database.
    async search({id, searchModel, forceUpdate, params, skip, take }) {
        this.MergeState({
            loadingSearch: true,
            loadingMetadata: !this.uiMetadata,
            isLoadingBtn: true,
        })

        if (!this.alldata || forceUpdate) {
            if (!this.uiMetadata || forceUpdate) {
                this.GetMetadata({ soId:id, forceReload: true });
            }
            return (
                this.Query({ model: searchModel, params, skip, take, id }).then((r) => {
                    if (r?.Success) {
                        const filteredData = r.Items?.first()?.Data ?? []
                        this.MergeState({
                            allData: r.Items?.first()?.Data ?? [],
                            loadingSearch: false,
                            isLoadingBtn: false,
                            filteredData: filteredData
                        })
                        return filteredData
                    }
                    else if (r) {
                        this.MergeState({
                            allData: r.Items?.first()?.Data ?? [],
                            loadingSearch: false,
                            isLoadingBtn: false,
                        })
                        return
                    }
                    else {
                        console.log("Search query returned undefined/null.");

                    }
                })
            )
        } else {
            this.MergeState({ loadingSearch: false, isLoadingBtn: false })
            return this.filteredData
        }
    }

    async GetSelectData(fieldMetadata) {
        return [];
    }

    async RunOperation({ operationName, id, item, queryParams, isFile, isItem, isWarning, processorType }) {
        const resultFunction = (r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                if (r.first().UICommands && r.first().UICommands["UpdateTable"]) {
                    this._updateTableData(r.first())
                }

                if (r.first().UICommands && r.first().UICommands["RefreshTable"]) {
                    this.search({ forceUpdate: true })
                }
            }

            if (r.first().UICommands && r.first().UICommands["Download"]) {
                const link = document.createElement('a');
                link.href = r.first().UICommands["Download"];
                link.click();
            }

            return r?.first();
        }
        let opResult;
        if (item) {
            opResult = await this._itemService.postOperation({ operationType: operationName, id, item, queryParams, isFile, isItem, isWarning, processorType }).then(resultFunction);
        }
        else {
            opResult = await this._itemService.getOperation({ operationType: operationName, id, queryParams, isFile, isWarning, processorType }).then(resultFunction);
        }
        return opResult;
    }


    async saveItem(item) {
       throw "Save item not supported by convoke objects."
    }

    async deleteItem(item, deletePermanently) {
        throw "Delete not supported in Convoke.";
    }

    async GetFile(id, fileName) {
        throw "Get file not supported in convoke.";
    }

    async GetFileUrl(id) {
        throw "File Urls not supported in convoke.";
    }
}

export default ConvokeSourceObjectManager;