import React, { useState, useEffect } from 'react'
import _ from "lodash"
import WizardFooter from './WizardFooter'
import { FieldControl, Loading } from 'spark-core-dx/components';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks';
import Accordion from './Accordion';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import BuildOpsSetup from '../systemApplicationSetup/BuildOpsSetup';
const SystemOfRecords = ({ setCurrentTab, applicationList, config, setConfig }) => {
    const itemService = new ItemServiceFactory()
    const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);
    const { handleProgress, currentStep, setCurrentStep, clientItem, updateOnboardingProcess } = useWizardDomainContext();
    const { hasAndShowValidationErrors, getValidationErrors } = useValidationErrors();
    const [buildOpsData, setBuildOpsData] = useState({})

    const [errorApps, setErrorApps] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (config?.BuildOpsConnectionString && _.isEmpty(buildOpsData)) {
            const entries = config?.BuildOpsConnectionString.split(';').map((entry) => {
                const [key, value] = entry.split('=');
                return [key, value];
            });
            setBuildOpsData(Object.fromEntries(entries))
        }
    }, [config])

    useEffect(() => {
        if (applicationList) {
            setIsLoading(false)
        }
    }, [applicationList])

    const handleProgressUpdate = () => {
        let filledFields = 0;
        let totalFields = 0;

        // Each application has it's own requirements.

        applicationList?.forEach((app) => {
            switch (app) {
                case 'BuildOps':
                    if (_.every(buildOpsData, (key, value) => value)) {
                        filledFields++;
                    }
                    totalFields++;
                    break;
                case 'Sage':

                    break;

            }
        })
        return { filledFields, totalFields }
        // handleProgress(filledFields, totalFields, 'page71');
    }

    const returnApplications = (appName) => {
        switch (appName) {
            case "BuildOps": return <BuildOpsSetup data={buildOpsData} appName={appName} errorApps={errorApps} updateData={(v, fn) => {
                const copiedBuildOpsData = { ...buildOpsData }
                copiedBuildOpsData[fn] = v
                setBuildOpsData(copiedBuildOpsData)
            }} />
        }

    }
    const handleSave = async (isContinue) => {
        if (!hasAndShowValidationErrors('application')) {
            const copiedConfig = { ...config }
            copiedConfig.BuildOpsConnectionString = `clientId=${buildOpsData.clientId};url=${buildOpsData.url};clientSecret=${buildOpsData.clientSecret};tenantId=${buildOpsData.tenantId};version=${buildOpsData.version}`
            await configMgr.saveItem(copiedConfig).then(r => {
                if (r.Success) {
                    setConfig(r.Items.first())
                }
            })
            const { filledFields, totalFields } = handleProgressUpdate();
            if (isContinue) {
                setCurrentTab(2)
            }
            await handleProgress(filledFields, totalFields, 'page71', updatedPageValues => {
                updateOnboardingProcess(currentStep, updatedPageValues);
            });
            return true;
        } else {
            const listOfErrors = getValidationErrors('application')
            const errorList = Object.keys(listOfErrors).filter(x => x !== "__updatedDate").map(y => { return y.split('-')[0] })
            setErrorApps([...new Set(errorList)])
            return false;
        }
    }
    return (
        <>
            <div className='wizard-content'>
                {!isLoading ? applicationList?.map(appName => {
                    return returnApplications(appName);
                })
                    : <Loading type={'spinner-2'} />
                }
            </div>
            <WizardFooter
                disableAll={isLoading}
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentStep(currentStep - 1)}
                showSecBtn={true}
                primaryBtnOnClick={async () => await handleSave(true)}
                secBtnOnClick={async () => await handleSave()}
                primaryBtnText={"Save & Continue"}
                secBtnText={"Save"} />
        </>
    )
}

export default SystemOfRecords